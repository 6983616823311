<template>
  <div class="upload-wrapper">
    <input
      ref="fileInput"
      type="file"
      :accept="fileInputParams.accept || acceptableFormats"
      :capture="fileInputParams.capture"
      class="d-none"
      @change="onFileChange"
    />
    <Card
      v-if="selectedFile"
      class="file-card rounded-lg d-flex mt-4"
    >
      <v-icon
        left
        aria-hidden="true"
        class="color--text-secondary"
      >
        mdi-image-outline
      </v-icon>
      <div class="file-info d-flex flex-fill flex-column">
        <p class="file-name mr-5">{{ fileName }}</p>
        <p class="text-body-2 color--text-secondary">{{ fileSize }}</p>
      </div>
      <v-btn
        icon
        class="color--text-secondary"
        @click="deleteFile"
      >
        <v-icon>mdi-delete-outline</v-icon>
      </v-btn>
    </Card>

    <v-menu
      v-if="enabledMenu"
      offset-y
      left
    >
      <template #activator="{ on, attrs }">
        <v-btn
          icon
          :disabled="disabled"
          v-bind="attrs"
          v-on="on"
        >
          <v-icon :class="[selectedFile ? 'color--text-primary' : 'color--text-tertiary']">
            mdi-paperclip
          </v-icon>
        </v-btn>
      </template>
      <v-list class="upload-menu">
        <v-list-item
          v-for="(item, index) in items"
          :key="index"
          class="cursor-pointer"
          @click="handleItemClick(item)"
        >
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item>

        <FileUploadCameraBtn @input="onCameraFileChange" />
      </v-list>
    </v-menu>

    <v-btn
      v-else
      icon
      :disabled="disabled"
      @click="triggerFileInput"
    >
      <v-icon :class="[selectedFile ? 'color--text-primary' : 'color--text-secondary']">
        mdi-paperclip
      </v-icon>
    </v-btn>
  </div>
</template>

<script setup>
import { computed, ref, nextTick } from "vue";
import Card from "@/ui/Card";
import { useIntl } from "@/shared/useIntl";
import FileUploadCameraBtn from "@/ui/FileUpload/FileUploadCameraBtn.vue";

defineProps({
  acceptableFormats: {
    type: String,
    default: ""
  },
  disabled: {
    type: Boolean,
    default: false
  },
  enabledMenu: {
    type: Boolean,
    default: false
  }
});

const emit = defineEmits(["select-file", "delete-file"]);

const fileInput = ref(null);
const selectedFile = ref(null);
const fileInputParams = ref({});
const { $t } = useIntl();

const triggerFileInput = () => {
  fileInput.value.click();
};

const onFileChange = (event) => {
  const file = event.target.files[0];
  if (file) {
    selectedFile.value = file;
    emit("select-file", file);
  }
};

const onCameraFileChange = (file) => {
  if (file) {
    selectedFile.value = file;
    emit("select-file", file);
  }
};

const fileName = computed(() => {
  return selectedFile.value ? selectedFile.value.name : "";
});

const fileSize = computed(() => {
  return selectedFile.value
    ? Math.round((selectedFile.value.size / Math.pow(1024, 2)) * 100) / 100 + "MB"
    : "";
});

const clearFileInput = () => {
  selectedFile.value = null;
  fileInput.value.value = "";
};

const deleteFile = () => {
  clearFileInput();
  emit("delete-file");
};

const handleItemClick = (item) => {
  fileInputParams.value = item;
  nextTick(() => {
    triggerFileInput();
  });
};

const items = [
  {
    title: $t("Photos & videos"),
    capture: false,
    accept: "image/*,video/*"
  },
  {
    title: $t("Files"),
    capture: false,
    accept: "image/*,video/*,.xlsx,.xls,.doc,.docx,.ppt,.pptx,.txt,.pdf"
  }
];

defineExpose({
  deleteFile,
  clearFileInput
});
</script>

<style scoped lang="scss">
@import "@/scss/_variables.scss";
.file-card {
  background-color: var(--bg-card-white);
  border: 1px solid var(--border-secondary);
}

.file-name {
  max-width: 124px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.upload-menu {
  min-width: rem(170px);
}

@media (max-width: map-get($grid-breakpoints, "sm")) {
  .upload-wrapper {
    max-width: 80%;
  }

  .file-info {
    max-width: 60%;
  }
}
</style>
