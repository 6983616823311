<template>
  <div class="camera">
    <v-dialog
      v-model="dialog"
      width="1000"
    >
      <template
        v-if="isMobile"
        #activator="{}"
      >
        <v-list-item
          class="cursor-pointer"
          @click="clickInput()"
        >
          {{ $t("Camera") }}
        </v-list-item>

        <input
          id="camera-input"
          type="file"
          accept="image/*"
          capture
          @change="emitFile"
        />
      </template>
      <template
        v-else
        #activator="{ on, attrs }"
      >
        <v-list-item
          class="cursor-pointer"
          v-bind="attrs"
          v-on="on"
        >
          {{ $t("Camera") }}
        </v-list-item>
      </template>
      <v-card>
        <DialogCloseButton :on-click="close" />
        <div
          v-if="isValid"
          class="cameraWrapper"
        >
          <video
            v-show="showVideo"
            id="videoPlayer"
            ref="player"
            class="camera"
            autoplay
            playsinline
          />
          <canvas
            v-show="!showVideo"
            ref="canvas"
            class="preview"
          />
          <div
            v-if="showVideo"
            class="capture"
            @click.prevent="capture"
          >
            <button></button>
          </div>
        </div>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            v-if="showVideo"
            color="error"
            @click.prevent="close"
            >{{ $t("Close") }}</v-btn
          >
          <v-btn
            v-if="!showVideo"
            color="error"
            @click.prevent="cancel"
            >{{ $t("Clear") }}</v-btn
          >
          <v-btn
            v-if="!showVideo"
            color="primary"
            @click.prevent="done"
            >{{ $t("Accept") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapGetters } from "vuex";

import DialogCloseButton from "@/components/DialogCloseButton";
import { convertBase64ImageToFile } from "@/utils/convertBase64ImageToFile";

export default {
  components: {
    DialogCloseButton
  },
  props: {
    value: {
      type: String,
      default: null
    },
    transparent: {
      default: false,
      type: Boolean
    }
  },
  data() {
    return {
      showVideo: true,
      picture: null,
      isValid: true,
      dialog: false,
      videoPlayer: null,
      canvasElement: null
    };
  },
  computed: {
    ...mapGetters({
      isMobile: "app/IsMobile"
    })
  },
  watch: {
    dialog: function () {
      if (this.dialog) {
        this.isValid = true;
        let context = this;
        setTimeout(function () {
          context.videoPlayer = context.$refs.player;
          context.canvasElement = context.$refs.canvas;
          context.streamUserMediaVideo();
        }, 1000);
      }
    }
  },
  methods: {
    streamUserMediaVideo() {
      if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
        return;
      }
      navigator.mediaDevices
        .getUserMedia({ video: true })
        .then((stream) => (this.videoPlayer.srcObject = stream))
        .catch(() => {
          this.isValid = false;
        });
    },
    capture() {
      this.showVideo = false;
      this.canvasElement.width = this.videoPlayer.videoWidth;
      this.canvasElement.height = this.videoPlayer.videoHeight;

      var context = this.canvasElement.getContext("2d");
      context.translate(this.canvasElement.width, 0);
      context.scale(-1, 1);

      context.drawImage(this.$refs.player, 0, 0);

      this.stopVideoStream();
      this.picture = this.$refs.canvas.toDataURL();
    },
    close() {
      this.stopVideoStream();
      this.showVideo = true;
      this.dialog = false;
    },
    async done() {
      this.stopVideoStream();

      const file = await convertBase64ImageToFile(this.picture, "pic_" + new Date().toISOString());
      this.$emit("input", file);

      this.showVideo = true;
      this.dialog = false;
    },
    cancel() {
      this.showVideo = true;
      this.streamUserMediaVideo();
    },
    stopVideoStream() {
      if (!(this.$refs.player && this.$refs.player.srcObject)) return;
      this.videoPlayer.srcObject.getTracks().forEach((track) => {
        track.stop();
        track.enabled = false;
      });
    },
    clickInput() {
      let el = document.getElementById("camera-input");
      if (el) {
        el.click();
      }
    },
    emitFile(event) {
      this.$emit("input", event.target.files[0]);
    }
  }
};
</script>
<style lang="scss">
@import "../../scss/variables";
.camera {
  display: flex;
  flex: 1;

  button {
    margin-right: 0.5rem;
    .fa {
      font-size: 1.25rem;
    }
  }
  input {
    display: none;
  }
}
.cameraWrapper {
  padding: 1rem;
  width: 100%;
  margin-top: 0.5rem;
  .camera {
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
  }
  .camera,
  canvas {
    z-index: 8;
    border-radius: 0.25rem;
    width: 100%;
  }
  .capture {
    z-index: 999;
    margin: auto;
    background: $white;
    height: 4rem;
    width: 4rem;
    border-radius: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: 0.25rem;
    left: 50%;
    transform: translateX(-50%);
    button {
      height: 3.5rem;
      width: 3.5rem;
      border: 3px solid $cardColor;
      border-radius: 2rem;
      transition: all 0.2s ease-in-out;
      &:focus {
        outline: none;
        border: 3px solid $blue;
      }
    }
  }
}
</style>
