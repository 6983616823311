<template>
  <div class="card top-reasons">
    <div class="chart-title-icons">
      <span class="header-text">{{ $t("Top LDMS Issues") }}</span>
      <div class="chart-icons">
        <v-icon
          class="bar-chart-icon"
          :color="showBarChart ? 'primary' : null"
          @click="handleShowBarChart"
        >
          mdi-chart-bar
        </v-icon>
        <v-icon
          class="tree-map-icon"
          :color="showTreeMap ? 'primary' : null"
          @click="handleShowTreeMap"
        >
          mdi-chart-tree
        </v-icon>
      </div>
    </div>
    <div class="analytics-card-header">
      <AnalyticsPagination :handleTabChange="handleTabChange" />
    </div>
    <div class="analytics-card-content">
      <TreeMapChart
        v-if="showTreeMap"
        class="tree-map"
        :data="reasonsTreeMapData"
        :id="'reasons-tree-map'"
        :handleClick="(event) => handleTreeMapClick(event)"
      />
      <BarChart
        v-if="showBarChart"
        class="bar-chart"
        :chartData="reasonsBarChartData"
        :axisType="AXISTYPE.SHORTEN"
        :click-function="(event, clickedElements) => handleBarChartClick(event, clickedElements)"
      />
    </div>
  </div>
</template>
<script>
import AnalyticsPagination from "@/components/AnalyticsPagination";
import BarChart, { AXISTYPE } from "@/components/charts/BarChart";
import TreeMapChart from "@/components/charts/TreeMapChart";
import { coralReef } from "@/scss/_variables.js";
import {
  getDatasets,
  handleBackgroundColorsNoOthersPresent,
  handleDataClickColors,
  handleOtherClickColors
} from "@/utils/LDMSAnalyticsUtils";
import { hexToRGB } from "@/utils/colors";

export default {
  name: "LDMSAnalyticsReasons",
  props: {
    filters: { type: Object, default: () => {} },
    interactiveFilters: { type: Object, default: () => {} },
    predefinedReasons: { type: Array, default: () => [] }
  },
  components: {
    AnalyticsPagination,
    TreeMapChart,
    BarChart
  },
  data() {
    return {
      AXISTYPE: AXISTYPE,
      showTreeMap: true,
      showBarChart: false,
      reasonsTreeMapData: [],
      reasonsBarChartData: {},
      reasonIds: [],
      pageSize: 10,
      otherReasons: []
    };
  },
  created() {
    this.getTopReasonsData();
  },
  methods: {
    handleShowBarChart() {
      this.showTreeMap = false;
      this.showBarChart = true;
    },
    handleShowTreeMap() {
      this.showBarChart = false;
      this.showTreeMap = true;
    },
    isOtherReasons(label) {
      return this.otherReasons.length > 0 && label === this.$t("Other");
    },
    otherReasonsExistsInFilter() {
      return this.otherReasons.some(
        (otherReason) =>
          this.interactiveFilters.reasons.findIndex((reason) => otherReason.id === reason.id) >= 0
      );
    },
    allNonOtherReasonsExistInFilter() {
      return this.reasonIds.every(
        (id) => this.interactiveFilters.reasons.findIndex((reason) => id === reason.id) >= 0
      );
    },
    updateDataBackgroundColors() {
      this.reasonsBarChartData.datasets[0].backgroundColor = handleDataClickColors(
        this.reasonsBarChartData.datasets[0].backgroundColor,
        this.interactiveFilters.reasons
      );
      const treeMapColors = this.reasonsTreeMapData.map((data) => data.color);
      const newTreeMapColors = handleDataClickColors(
        treeMapColors,
        this.interactiveFilters.reasons
      );
      this.reasonsTreeMapData = this.reasonsTreeMapData.map((data, index) => ({
        ...data,
        color: newTreeMapColors[index]
      }));
    },
    updateOtherBackgroundColors() {
      const allNonOtherReasonsExistInFilter = this.allNonOtherReasonsExistInFilter();
      this.reasonsBarChartData.datasets[0].backgroundColor = handleOtherClickColors(
        this.reasonsBarChartData.datasets[0].backgroundColor,
        allNonOtherReasonsExistInFilter
      );
      const treeMapColors = this.reasonsTreeMapData.map((data) => data.color);
      const newTreeMapColors = handleOtherClickColors(
        treeMapColors,
        allNonOtherReasonsExistInFilter
      );
      this.reasonsTreeMapData = this.reasonsTreeMapData.map((data, index) => ({
        ...data,
        color: newTreeMapColors[index]
      }));
    },
    updateBackgroundColorsNoOthersPresent() {
      this.reasonsBarChartData.datasets[0].backgroundColor = handleBackgroundColorsNoOthersPresent(
        this.reasonsBarChartData.datasets[0].backgroundColor,
        this.interactiveFilters.reasons
      );
      const treeMapColors = this.reasonsTreeMapData.map((data) => data.color);
      const newTreeMapColors = handleBackgroundColorsNoOthersPresent(
        treeMapColors,
        this.interactiveFilters.reasons
      );
      this.reasonsTreeMapData = this.reasonsTreeMapData.map((data, index) => ({
        ...data,
        color: newTreeMapColors[index]
      }));
    },
    async handleTabChange(pageSize) {
      this.pageSize = pageSize;
      await this.getTopReasonsData();
      if (this.interactiveFilters.reasons.length === 0) {
        return;
      }
      if (this.otherReasonsExistsInFilter()) {
        this.updateDataBackgroundColors();
        return;
      }
      this.updateBackgroundColorsNoOthersPresent();
    },
    handleTreeMapClick(event) {
      const elementIndex = event.target._dataItem._index;
      const label = event.target._dataItem.treeMapDataItem.name;
      if (this.isOtherReasons(label)) {
        this.setInteractiveFilterForOther();
        return;
      }
      const foundItemIndex = this.reasonsTreeMapData.findIndex((data) => data.name === label);
      if (foundItemIndex >= 0) {
        this.setInteractiveFilter(foundItemIndex);
      }
    },
    handleBarChartClick(event, clickedElements) {
      if (clickedElements.length === 0) {
        return;
      }
      const elementIndex = clickedElements[0]._index;
      const label = clickedElements[0]._model.label;
      if (this.isOtherReasons(label)) {
        this.setInteractiveFilterForOther();
        return;
      }
      this.setInteractiveFilter(elementIndex);
    },
    setInteractiveFilterForOther() {
      if (!this.otherReasonsExistsInFilter()) {
        this.interactiveFilters.reasons.push(...this.otherReasons);
      } else {
        const filterCopy = this.interactiveFilters.reasons.slice();
        this.otherReasons.forEach((otherReason) => {
          const foundReasonIndex = filterCopy.findIndex((reason) => otherReason.id === reason.id);
          if (foundReasonIndex >= 0) {
            filterCopy.splice(foundReasonIndex, 1);
          }
        });
        this.interactiveFilters.reasons = filterCopy.slice();
      }
      this.updateOtherBackgroundColors();
    },
    setInteractiveFilter(elementIndex) {
      const reasonId = this.reasonIds[elementIndex];
      const foundReasonIndex = this.interactiveFilters.reasons.findIndex(
        (reason) => reason.id === reasonId
      );
      if (foundReasonIndex >= 0) {
        this.interactiveFilters.reasons.splice(foundReasonIndex, 1);
      } else {
        this.interactiveFilters.reasons.push({ id: reasonId, index: elementIndex });
      }
      if (this.otherReasonsExistsInFilter()) {
        this.updateDataBackgroundColors();
        return;
      }
      this.updateBackgroundColorsNoOthersPresent();
    },
    async getTopReasonsData() {
      const machines = this.interactiveFilters.machines.map((machine) => machine.id);
      const parts = this.interactiveFilters.parts.map((part) => part.id);
      const params = {
        from_date: this.filters.fromDate,
        to_date: this.filters.toDate,
        machine_group: this.filters.machineGroups,
        machine_ids: machines.length > 0 ? machines : this.filters.machines,
        part_numbers: parts.length > 0 ? parts : this.filters.parts,
        reason_ids: this.filters.reasons
      };
      if (this.pageSize) {
        params.pageSize = this.pageSize;
      }
      try {
        const response = await this.$http.get("exceptions/top_reasons/", { params });
        if (response && response.data) {
          this.otherReasons = response.data.filter(
            (item) =>
              this.predefinedReasons.findIndex((reason) => reason.text === item.reason_name) === -1
          );
          this.otherReasons = this.otherReasons.map((reason) => ({
            ...reason,
            id: reason.reason_id
          }));
          const data = response.data.filter(
            (item) =>
              this.predefinedReasons.findIndex((reason) => reason.text === item.reason_name) >= 0
          );
          this.reasonIds = data.map((reason) => reason.reason_id);
          // const colors = [...Array(this.otherReasons.length > 0 ? data.length + 1 : data.length).fill(0).map(() => getColor())];
          const colors = [
            ...Array(this.otherReasons.length > 0 ? data.length + 1 : data.length)
              .fill(0)
              .map(() => hexToRGB(coralReef, "0.9"))
          ];
          this.reasonsTreeMapData = data.map((reason, index) => {
            return {
              name: reason.reason_name,
              value: reason.number_of_issues,
              color: colors[index]
            };
          });
          this.reasonsBarChartData = {
            labels: data.map((reason) => reason.reason_name),
            datasets: getDatasets(data, colors)
          };
          if (this.otherReasons.length > 0) {
            let otherIssueTotal = 0;
            this.otherReasons.forEach((reason) => (otherIssueTotal += reason.number_of_issues));
            this.reasonsTreeMapData.push({
              name: this.$t("Other"),
              value: otherIssueTotal,
              color: colors[colors.length - 1]
            });
            this.reasonsBarChartData.labels.push(this.$t("Other"));
            this.reasonsBarChartData.datasets[0].data.push(otherIssueTotal);
          }
        }
        Promise.resolve();
      } catch (error) {
        console.error(error);
        Promise.resolve();
      }
    }
  }
};
</script>
<style lang="scss"></style>
