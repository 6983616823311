<template>
  <v-app>
    <div
      v-if="env.enableDocs"
      class="main-cont d-flex flex-column align-items-start py-5 px-4"
    >
      <p class="text-h4 font-weight-bold color--text-primary mb-5">
        {{ $t("Existing reusable components:") }}
      </p>
      <ListComponent :components="components" />
    </div>
  </v-app>
</template>

<script setup>
import ListComponent from "./ListComponent.vue";
import { ref } from "vue";
import { env } from "@/shared/env";

const components = ref([
  {
    name: "Activity Events",
    components: [
      {
        name: "ActivityEventItem",
        description: "Component to display activities"
      },
      {
        name: "ActivityEvents",
        description: "Component to display active events"
      }
    ]
  },
  {
    name: "AudioPlayer",
    description: "Component for showing audio player with or no provided url"
  },
  {
    name: "Btn",
    description: "Custom button based on vuetify btn component with configured color, sizes etc."
  },
  {
    name: "Cards",
    components: [
      {
        name: "Card",
        description: "Component for showing vuetify card background"
      },
      {
        name: "CardInfo",
        description: "Component for the card information inside card"
      },
      {
        name: "CardMetrics",
        description: "Component for card metrics"
      }
    ]
  },
  {
    name: "Charts",
    components: [
      {
        name: "BarChart",
        description: "Component for the bar chart"
      },
      {
        name: "BarChartPaginated",
        description: "Component for the paginated bar chart"
      },
      {
        name: "ChartBarOneColumn",
        description: "Component for the one column bar chart"
      },
      {
        name: "ChartPagination",
        description: "Component to paginate bar charts"
      },
      {
        name: "HorizontalOneBarChart",
        description:
          "Component for the horizontal bar chart to display values in percentage by different colors"
      }
    ]
  },
  {
    name: "CheckboxGroup",
    description: "Component for displaying checkboxes from the provided array"
  },
  {
    name: "CommentControlRestyled",
    description: "Component for the whole comment control page"
  },
  {
    name: "Durations",
    components: [
      {
        name: "DateDuration",
        description: "Component to adapt timestamps to needed format"
      },
      {
        name: "MachineEventsDuration",
        description: "Component to display date ranges (min, max) as a numbers"
      }
    ]
  },
  {
    name: "DialogClose",
    description: "Component for the closing the dialog by clicking on icon"
  },
  {
    name: "DropdownAutocomplete",
    description:
      "Component for the dropdown autocomplete with an ability to concat strings, search and others"
  },
  {
    name: "DynamicTable",
    description:
      "Component for vuetify table with dynamic values and ability to add difficult logic for the items"
  },
  {
    name: "FilterSwitch",
    description: "Switch controller to change true/false values"
  },
  {
    name: "File Uploads",
    components: [
      { name: "FileUpload", description: "Component to upload files" },
      { name: "FileUploadCameraBtn", description: "File upload button with camera icon" }
    ]
  },
  { name: "MarkDown", description: "Component to convert markdown to html" },
  { name: "MobileOnlyExpansion", description: "Component for the mobile expansion" },
  {
    name: "ModalDialog",
    description:
      "Component for modal dialog with slots for content, dialog closing icon and buttons such as submit, cancel etc."
  },
  {
    name: "PreloaderPanel",
    description: "Component to display preloader panel (spinner) while waiting of http response"
  },
  {
    name: "SpinnerDownloadingCsv",
    description: "Component for the spinner to blur background while waiting on csv download"
  },
  {
    name: "Presets Filters",
    components: [
      {
        name: "PresetsDateFilters",
        description: "Component for presets date filters with datepickers for custom dates"
      },
      {
        name: "PresetsFilters",
        description: "Component for presets filters with buttons for shift, day and other presets"
      }
    ]
  },
  {
    name: "RichTextEditor",
    description: "Component to edit a text with different colors, fonts and other options"
  },
  {
    name: "Roles Guard",
    components: [
      {
        name: "RolesGuard",
        description: "Component to check some needed roles provided as a prop"
      },
      { name: "RolesGuardStrict", description: "Component to check all roles provided as a prop" }
    ]
  },
  { name: "RouterBreadcrumbs", description: "Component for breadcrumb navigation" },
  { name: "ScreenTransition", description: "Component for screen transitions based in v-tabs" },
  { name: "SearchField", description: "Component to search the provided data" },
  { name: "Sidebar", description: "Component for the sidebar navigation" },
  { name: "TableMultivalue", description: "Table with multiple values per cell" },
  {
    name: "Text Fields",
    components: [
      { name: "TextField", description: "Component for the input text" },
      { name: "TextLink", description: "Component for creating a text link" },
      { name: "TextOverflow", description: "Component to handle text overflow" },
      { name: "TextOverflowTooltip", description: "Component to show tooltip on text overflow" }
    ]
  },
  { name: "ThemeSwitcher", description: "Component to switch between different themes" },
  { name: "UserAvatar", description: "Component displays a user profile picture" },
  { name: "ViewSwitcher", description: "Switcher to switch between old and new design view" },
  {
    name: "Workflow Events",
    components: [
      {
        name: "WorkflowEventItem",
        description: "Component to display a single workflow event item"
      },
      { name: "WorkflowEvents", description: "Component to display workflow events" }
    ]
  }
]);

defineExpose(components);
</script>

<style scoped lang="scss">
.main-cont {
  height: 100vh;
  overflow-y: auto;
}
</style>
