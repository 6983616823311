<template>
  <ModalDialog
    content-class="close-ticket-dialog"
    :value="open"
    persistent
    max-width="768px"
    full-screen-mobile
    height="500px"
    @close="$emit('close')"
  >
    <template #title> {{ $t("Close ticket") }} </template>

    <template #body>
      <form
        id="ticket-details"
        class="ticket-details"
        @submit.prevent="submit"
      >
        <div class="text-subtitle-2 font-weight-regular">
          {{ $t("Select a resolution for a ticket") }}
        </div>

        <v-radio-group
          v-model="solutionType"
          class="mt-2 mb-5 pt-0"
          hide-details
        >
          <v-radio
            v-for="item in BUTTONS"
            :key="item.value"
            class="mb-4"
            :label="item.title"
            :value="item.value"
          ></v-radio>
        </v-radio-group>

        <v-textarea
          v-model="solutionDetails"
          :error="Boolean(textareaError)"
          :error-messages="textareaError"
          :hide-details="!textareaError"
          class="close-textarea"
          rows="4"
          :required="solutionDetailsRequired"
          filled
          :label="$t('Solution*')"
        />

        <v-alert
          v-if="errMessage"
          type="error"
          class="mt-4"
        >
          {{ errMessage }}
        </v-alert>
      </form>
    </template>
    <template #actions>
      <btn
        class="btn-close"
        primary
        @click="() => $emit('close')"
      >
        {{ $t("Cancel") }}
      </btn>
      <btn
        class="btn-close"
        variant="error"
        :loading="closeTicketLoading"
        type="submit"
        form="ticket-details"
      >
        {{ $t("Close") }}
      </btn>
    </template>
  </ModalDialog>
</template>

<script setup>
import { computed, ref, toRefs, watch } from "vue";

import Btn from "@/ui/Btn/Btn.vue";
import ModalDialog from "@/ui/ModalDialog/ModalDialog.vue";
import { TICKET_STATUS } from "@/features/ticket-details/constants";
import { useIntl } from "@/shared/useIntl";
import { useTicketClose } from "@/features/ticket-details/useTicketClose";

const props = defineProps({
  ticket: { type: Object, default: () => undefined },
  charLimits: { type: Object, default: () => ({}) },
  open: { type: Boolean, default: false, required: true }
});

const TICKET_DETAILS_MIN_LENGTH = 15;
const { open, charLimits } = toRefs(props);

const solutionType = ref(TICKET_STATUS.RESOLVED);
const solutionDetails = ref("");
const textareaError = ref("");

const emit = defineEmits(["close"]);

const { $t } = useIntl();
const {
  mutate: closeTicket,
  isLoading: closeTicketLoading,
  error
} = useTicketClose(props.ticket.id, () => {
  emit("close");
});

watch(open, () => {
  solutionType.value = TICKET_STATUS.RESOLVED;
  solutionDetails.value = "";
});

const BUTTONS = [
  {
    title: $t("Issue resolved"),
    value: TICKET_STATUS.RESOLVED
  },
  {
    title: $t("Issue can't be resolved"),
    value: TICKET_STATUS.CAN_NOT_BE_RESOLVED
  },
  {
    title: $t("Ticket created by mistake"),
    value: TICKET_STATUS.CREATED_BY_MISTAKE
  }
];

const solutionDetailsRequired = computed(() => {
  return solutionType.value !== TICKET_STATUS.CREATED_BY_MISTAKE;
});

const charLimitsMap = {};
charLimitsMap[TICKET_STATUS.RESOLVED] = charLimits.value.p911ResolvedSolutionCharLimit;
charLimitsMap[TICKET_STATUS.CAN_NOT_BE_RESOLVED] =
  charLimits.value.p911CanNotBeResolvedSolutionCharLimit;
charLimitsMap[TICKET_STATUS.CREATED_BY_MISTAKE] =
  charLimits.value.p911CreatedByMistakeSolutionCharLimit;

const checkTextarea = () => {
  if (
    charLimitsMap[solutionType.value] &&
    solutionDetails.value?.length > charLimitsMap[solutionType.value]
  ) {
    textareaError.value = $t("The maximum number of characters/symbols is {maxLength}", {
      maxLength: charLimitsMap[solutionType.value]
    });
  } else if (solutionType.value === TICKET_STATUS.CREATED_BY_MISTAKE) {
    textareaError.value = "";
  } else if (!solutionDetails.value) {
    textareaError.value = $t("This field is required");
  } else if (solutionDetails.value && solutionDetails.value.length < TICKET_DETAILS_MIN_LENGTH) {
    textareaError.value = $t("Describe a solution (min {minLength} characters/symbols)", {
      minLength: TICKET_DETAILS_MIN_LENGTH
    });
  } else {
    textareaError.value = "";
  }

  return !textareaError.value;
};

const submit = async () => {
  const valid = checkTextarea();

  if (valid) {
    await closeTicket({
      ticket_id: props.ticket.id,
      solutionType: solutionType.value,
      solutionDetails: solutionDetails.value
    });
  }
};

const errMessage = computed(() => {
  if (error.value) {
    return error?.value[0]?.message || $t("Something went wrong");
  }

  return null;
});

defineExpose({
  BUTTONS,
  solutionType,
  solutionDetails,
  closeTicketLoading,
  submit,
  errMessage
});
</script>

<style scoped lang="scss">
@import "@/scss/_variables.scss";

.btn-close {
  min-width: 168px !important;

  @media (max-width: map-get($grid-breakpoints, "sm")) {
    --padding: 6px;

    min-width: 0 !important;
    width: calc(50% - var(--padding));
    flex-grow: 1;

    &:first-of-type {
      margin-right: var(--padding) !important;
    }

    &:last-of-type {
      margin-left: var(--padding) !important;
    }

    &::v-deep.v-btn.button {
      height: 52px;
    }
  }
}

.ticket-details ::v-deep {
  .v-radio.v-item--active .v-label {
    color: var(--btn-primary) !important;
  }
}

.v-radio {
  min-height: 44px;
  margin-bottom: 0 !important;

  & ~ .v-radio {
    margin-top: 4px !important;
  }
}
</style>

<style lang="scss">
.close-ticket-dialog {
  .body {
    padding-bottom: 0 !important;

    @media (max-width: map-get($grid-breakpoints, "sm")) {
      padding-top: 4px !important;
    }
  }
}

.close-textarea ::v-deep textarea {
  max-height: 180px;
}
</style>
