<template>
  <div class="card cost-by-part">
    <div class="chart-title-icons">
      <span class="header-text">{{ $t("Cost By Part") }}</span>
      <div class="chart-icons">
        <v-icon
          class="table-icon"
          :color="showTable ? 'primary' : null"
          @click="handleShowTable"
        >
          mdi-table
        </v-icon>
        <v-icon
          class="bar-chart-icon"
          :color="showBarChart ? 'primary' : null"
          @click="handleShowBarChart"
        >
          mdi-chart-bar
        </v-icon>
      </div>
    </div>
    <div class="loading-bar">
      <v-progress-linear
        indeterminate
        v-if="loading"
      />
    </div>
    <div
      v-if="showBarChart"
      class="chart-container"
    >
      <BarChart
        class="bar-chart"
        :chartData="chartData"
        :axisType="AXISTYPE.SHORTEN"
        :commas="true"
        :uom="'$'"
      />
      <div class="paginator">
        <BarChartPaginator
          v-if="!loading"
          :refresh="filters"
          :rawData="rawData"
          :sortMethod="sortData"
          default-sort="desc"
          :buildChart="buildChart"
          :defaultQuantity="10"
        />
      </div>
    </div>
    <div
      class="table-container"
      v-if="showTable"
    >
      <GenericTable
        :columns="columns"
        :data="tableData"
        :footer="footer"
      />
    </div>
  </div>
</template>
<script>
import BarChartPaginator from "@/components/BarChartPaginator";
import GenericTable from "@/components/GenericTable";
import BarChart, { AXISTYPE } from "@/components/charts/BarChart";
import { tealBlue } from "@/scss/_variables.js";
import { hexToRGB } from "@/utils/colors";
import { getDatasets } from "@/utils/scrapAnalyticsUtils";

export default {
  name: "ScrapAnalyticsCostByPart",
  props: {
    filters: { type: Object, default: () => {} }
  },
  components: {
    BarChart,
    GenericTable,
    BarChartPaginator
  },
  data() {
    return {
      AXISTYPE: AXISTYPE,
      showBarChart: true,
      showTable: false,
      chartData: {},
      loading: false,
      columns: [
        { title: this.$t("Part"), key: "part" },
        { title: this.$t("Cost"), key: "formattedCost" }
      ],
      tableData: [],
      rawData: []
    };
  },
  computed: {
    cost() {
      return `$ ${this.tableData.reduce((acc, cur) => acc + cur.cost, 0).toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`;
    },
    footer() {
      return [
        { type: "text", text: this.$t("Total") },
        { type: "text", text: this.cost }
      ];
    }
  },
  methods: {
    handleShowBarChart() {
      this.showTable = false;
      this.showBarChart = true;
    },
    handleShowTable() {
      this.showBarChart = false;
      this.showTable = true;
    },
    sortData(data, sortDirection) {
      data.sort((a, b) => {
        const valueA = a.cost;
        const valueB = b.cost;
        return sortDirection === "desc" ? valueB - valueA : valueA - valueB;
      });
      return data;
    },
    buildChart(data) {
      this.chartData = {};
      const colors = data.map(() => hexToRGB(tealBlue, "0.9"));
      this.chartData = {
        labels: data.map((item) => item.id),
        datasets: getDatasets(data, colors)
      };
    },
    async getCostByPart() {
      this.loading = true;
      const params = {
        from_date: this.filters.fromDate,
        to_date: this.filters.toDate
      };
      if (this.filters && this.filters.machines && this.filters.machines.length > 0) {
        params.machine_ids = this.filters.machines;
      }
      if (this.filters && this.filters.partNumbers && this.filters.partNumbers.length > 0) {
        params.part_numbers = this.filters.partNumbers;
      }
      try {
        const response = await this.$http.get("scrap-reporting/scrap_cost_by_part/", { params });
        if (response && response.data) {
          this.rawData = Object.keys(response.data).map((key) => ({
            id: key,
            cost: response.data[key]
          }));
          this.tableData = Object.keys(response.data).map((key) => ({
            id: key,
            part: key,
            cost: response.data[key],
            formattedCost: `$ ${response.data[key].toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`
          }));
        }
        this.loading = false;
      } catch (error) {
        console.error(error);
        this.loading = false;
      }
    }
  }
};
</script>
<style lang="scss">
@import "./ScrapAnalyticsCostByPart.scss";
</style>
