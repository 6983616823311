<template>
  <ModalDialog
    content-class="subscribe-dialog"
    :value="open"
    persistent
    max-width="400px"
    height="256px"
    @close="closeSubscribe"
  >
    <template #title> {{ $t("Add Ref ID") }} </template>
    <template #body>
      <label class="d-block text-body-1 color--text-secondary mb-2">{{ $t("Ref ID") }}</label>
      <TextField
        v-model="userID"
        large
        filled
        single-line
        hide-details
        clearable
      />
    </template>
    <template #actions>
      <Btn
        class="btn mb-1 mr-auto"
        elevation="0"
        primary
        @click="closeSubscribe"
      >
        {{ $t("Cancel") }}
      </Btn>
      <Btn
        class="btn mb-1"
        elevation="0"
        variant="primary"
        :disabled="false"
        :loading="loading"
        @click="subscribeUser"
      >
        {{ ticket.reference ? $t("Update") : $t("Add") }}
      </Btn>
    </template>
  </ModalDialog>
</template>

<script setup>
import { computed, ref, watch } from "vue";

import Btn from "@/ui/Btn/Btn.vue";
import ModalDialog from "@/ui/ModalDialog/ModalDialog.vue";
import TextField from "@/ui/TextField/TextField.vue";
import { useTicketDetailsSubscription } from "@/features/ticket-details/useTicketDetailsSubscription";

const props = defineProps({
  ticket: { type: Object, default: () => ({}) },
  open: { type: Boolean, default: false, required: true }
});

const emit = defineEmits(["close, success"]);

const userID = ref(props.ticket.reference);
const loading = ref(false);
const errors = ref(null);

const reference = computed(() => props.ticket.reference);

const { mutate } = useTicketDetailsSubscription();

const closeSubscribe = () => {
  emit("close");
};

const subscribeUser = () => {
  errors.value = null;
  const variables = {
    id: props.ticket.id,
    helpTicketData: {
      reference: userID.value
    }
  };
  loading.value = true;
  try {
    mutate(variables, {
      onSuccess: () => {
        closeSubscribe();
        loading.value = false;
        emit("success");
      },
      onError: (error) => {
        errors.value = error;
        loading.value = false;
      }
    });
  } catch (err) {
    console.error(err);
  }
};

watch(reference, (newVal) => {
  userID.value = newVal;
});

defineExpose({
  userID,
  loading,
  closeSubscribe,
  subscribeUser
});
</script>

<style scoped lang="scss">
.btn {
  min-width: 168px !important;

  @media (max-width: map-get($grid-breakpoints, "sm")) {
    --padding: 6px;

    min-width: 0 !important;
    width: calc(50% - var(--padding));
    flex-grow: 1;

    &:first-of-type {
      margin-right: var(--padding) !important;
    }

    &:last-of-type {
      margin-left: var(--padding) !important;
    }

    &::v-deep.v-btn.button {
      height: 52px;
    }
  }
}
</style>

<style lang="scss">
.subscribe-dialog {
  .body {
    padding-top: 16px !important;
    padding-bottom: 16px !important;
  }
  .footer {
    padding-top: 16px !important;
    padding-bottom: 12px !important;
  }
}
</style>
