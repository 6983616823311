<template>
  <div>
    <LDMSAnalyticsIssueCards :filters="filters" />
    <div class="trend-status-container">
      <div class="card trend-data">
        <p class="title">{{ $t("LDMS Trend Chart") }}</p>
        <BarChart
          class="bar-chart"
          :chartData="trendChartData"
          :chartOptions="trendChartOptions"
          :axisType="AXISTYPE.SHORTEN"
        />
      </div>
      <div class="card status-data">
        <p class="title">{{ $t("Status") }}</p>
        <PieChart
          v-if="showStatusChart"
          :chartData="statusChartData"
          :chartOptions="statusChartOptions"
          :backgroundColors="statusChartBackgroundColors"
        />
      </div>
    </div>
    <div class="group-level-charts-container">
      <div
        v-if="levelP1"
        class="card group-level-one"
      >
        <p class="title">{{ $t("Issues By") }} {{ levelP1 ? levelP1.name : "" }}</p>
        <div class="performance-summary-card-header">
          <AnalyticsPagination
            :handleTabChange="(pageSize) => handleTabChange(pageSize, 'groupLevelOne')"
          />
        </div>
        <BarChart
          class="bar-chart"
          :chartData="groupLevelOneChartData"
          :axisType="AXISTYPE.SHORTEN"
        />
      </div>
      <div
        v-if="levelP2"
        class="card group-level-two"
      >
        <p class="title">{{ $t("Issues By") }} {{ levelP2 ? levelP2.name : "" }}</p>
        <div class="performance-summary-card-header">
          <AnalyticsPagination
            :handleTabChange="(pageSize) => handleTabChange(pageSize, 'groupLevelTwo')"
          />
        </div>
        <BarChart
          class="bar-chart"
          :chartData="groupLevelTwoChartData"
          :axisType="AXISTYPE.SHORTEN"
        />
      </div>
    </div>
    <div class="card issues-by-machine-chart">
      <p class="title">{{ $t("Issues By Machine") }}</p>
      <div class="performance-summary-card-header">
        <AnalyticsPagination
          :handleTabChange="(pageSize) => handleTabChange(pageSize, 'issuesByMachine')"
        />
      </div>
      <BarChart
        class="bar-chart"
        :chartData="issuesByMachinesChartData"
        :axisType="AXISTYPE.SHORTEN"
      />
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";

import AnalyticsPagination from "@/components/AnalyticsPagination";
import BarChart, { AXISTYPE } from "@/components/charts/BarChart";
import PieChart from "@/components/charts/PieChart";
import { coralReef, peachPuff, tiffanyBlue } from "@/scss/_variables.js";
import { getTimeLabel } from "@/utils/LDMSAnalyticsUtils";

import LDMSAnalyticsIssueCards from "../LDMSAnalyticsIssueCards";

export default {
  name: "LDMSAnalyticsPerformanceSummaryView",
  props: {
    filters: { type: Object, default: () => {} },
    levelP1: { type: Object, default: () => {} },
    levelP2: { type: Object, default: () => {} }
  },
  components: {
    BarChart,
    PieChart,
    LDMSAnalyticsIssueCards,
    AnalyticsPagination
  },
  data() {
    return {
      AXISTYPE: AXISTYPE,
      trendChartOptions: {
        legend: {
          display: true
        }
      },
      statusChartOptions: {
        tooltips: {
          callbacks: {
            label: (item, data) =>
              data["labels"][item["index"]] + ": " + data["datasets"][0]["data"][item["index"]]
          }
        }
      },
      statusChartBackgroundColors: [tiffanyBlue, coralReef],
      pageSizes: {
        groupLevelOne: {
          pageSize: 10,
          callback: this.getLDMSByGroupLevelP1
        },
        groupLevelTwo: {
          pageSize: 10,
          callback: this.getLDMSByGroupLevelP2
        },
        issuesByMachine: {
          pageSize: 10,
          callback: this.getIssuesByMachines
        }
      },
      trendChartData: {},
      statusChartData: {},
      groupLevelOneChartData: {},
      groupLevelTwoChartData: {},
      issuesByMachinesChartData: {}
    };
  },
  created() {
    this.getLDMSTrendData();
    this.getLDMSByGroupLevelP1();
    this.getLDMSByGroupLevelP2();
    this.getIssuesByMachines();
  },
  computed: {
    ...mapGetters({
      machineGroupFromId: "dbCache/machineGroupFromId",
      machineLookup: "dbCache/machineFromPk"
    }),
    showStatusChart() {
      return Object.keys(this.statusChartData).length > 0;
    }
  },
  methods: {
    handleTabChange(pageSize, chart) {
      if (!(chart in this.pageSizes)) {
        return;
      }
      this.pageSizes[chart]["pageSize"] = pageSize;
      this.pageSizes[chart]["callback"](pageSize);
    },
    async getLDMSTrendData() {
      const params = {
        from_date: this.filters.fromDate ? this.filters.fromDate.toISOString() : "",
        to_date: this.filters.toDate ? this.filters.toDate.toISOString() : "",
        machine_ids: this.filters.machines,
        part_numbers: this.filters.parts,
        reason_ids: this.filters.reasons
      };
      if (this.filters.interval) {
        params.interval = this.filters.interval;
      }
      try {
        const response = await this.$http.get("exceptions/ldms_trend/", { params });
        if (response && response.data) {
          this.trendChartData = {
            datasets: [
              {
                type: "line",
                data: response.data.map((item) => item.doc_count),
                label: this.$t("LDMS Issues"),
                borderColor: peachPuff,
                fill: false
              },
              {
                data: response.data.map((item) => item.closed.doc_count),
                label: this.$t("Closed"),
                backgroundColor: response.data.map(() => tiffanyBlue)
              },
              {
                data: response.data.map((item) => item.open.doc_count),
                label: this.$t("Open"),
                backgroundColor: response.data.map(() => coralReef)
              }
            ],
            labels: response.data.map((item) =>
              getTimeLabel(item.key_as_string, this.filters.dateFormat)
            )
          };
          const closed = response.data.reduce((acc, curr) => acc + curr.closed.doc_count, 0);
          const open = response.data.reduce((acc, curr) => acc + curr.open.doc_count, 0);
          this.statusChartData = {
            datasets: [{ data: [closed, open] }],
            labels: [this.$t("Closed"), this.$t("Open")]
          };
        }
      } catch (error) {
        console.error(error);
      }
    },
    async getLDMSByGroupLevelP1(pageSize = 10) {
      if (!this.levelP1) {
        return;
      }
      const params = {
        from_date: this.filters.fromDate ? this.filters.fromDate.toISOString() : "",
        to_date: this.filters.toDate ? this.filters.toDate.toISOString() : "",
        group_level: this.levelP1.level,
        machine_ids: this.filters.machines,
        part_numbers: this.filters.parts,
        reason_ids: this.filters.reasons
      };
      if (pageSize) {
        params.pageSize = pageSize;
      }
      try {
        const response = await this.$http.get("exceptions/ldms_by_group/", { params });
        if (response && response.data) {
          const data = Object.keys(response.data)
            .map((key) => ({ key, ...response.data[key] }))
            .sort((a, b) => b.open.doc_count - a.open.doc_count);
          const labels = data.map((item) => {
            const foundGroup = this.machineGroupFromId(parseInt(item.key, 10));
            return foundGroup && foundGroup.name ? foundGroup.name : "";
          });
          this.groupLevelOneChartData = {
            datasets: [
              {
                data: data.map((item) => item.closed.doc_count),
                label: this.$t("Closed"),
                backgroundColor: data.map(() => tiffanyBlue)
              },
              {
                data: data.map((item) => item.open.doc_count),
                label: this.$t("Open"),
                backgroundColor: data.map(() => coralReef)
              }
            ],
            labels
          };
        }
      } catch (error) {
        console.error(error);
      }
    },
    async getLDMSByGroupLevelP2(pageSize = 10) {
      if (!this.levelP2) {
        return;
      }
      const params = {
        from_date: this.filters.fromDate ? this.filters.fromDate.toISOString() : "",
        to_date: this.filters.toDate ? this.filters.toDate.toISOString() : "",
        group_level: this.levelP2.level,
        machine_ids: this.filters.machines,
        part_numbers: this.filters.parts,
        reason_ids: this.filters.reasons
      };
      if (pageSize) {
        params.pageSize = pageSize;
      }
      try {
        const response = await this.$http.get("exceptions/ldms_by_group/", { params });
        if (response && response.data) {
          const data = Object.keys(response.data)
            .map((key) => ({ key, ...response.data[key] }))
            .sort((a, b) => b.open.doc_count - a.open.doc_count);
          const labels = data.map((item) => {
            const foundGroup = this.machineGroupFromId(parseInt(item.key, 10));
            return foundGroup && foundGroup.name ? foundGroup.name : "";
          });
          this.groupLevelTwoChartData = {
            datasets: [
              {
                data: data.map((item) => item.closed.doc_count),
                label: this.$t("Closed"),
                backgroundColor: data.map(() => tiffanyBlue)
              },
              {
                data: data.map((item) => item.open.doc_count),
                label: this.$t("Open"),
                backgroundColor: data.map(() => coralReef)
              }
            ],
            labels
          };
        }
      } catch (error) {
        console.error(error);
      }
    },
    async getIssuesByMachines(pageSize = 10) {
      const params = {
        from_date: this.filters.fromDate ? this.filters.fromDate.toISOString() : "",
        to_date: this.filters.toDate ? this.filters.toDate.toISOString() : "",
        machine_ids: this.filters.machines,
        part_numbers: this.filters.parts,
        reason_ids: this.filters.reasons
      };
      if (pageSize) {
        params.pageSize = pageSize;
      }
      try {
        const response = await this.$http.get("exceptions/ldms_by_machine/", { params });
        if (response && response.data) {
          response.data.sort((a, b) => b.open.doc_count - a.open.doc_count);
          this.issuesByMachinesChartData = {
            datasets: [
              {
                data: response.data.map((item) => item.closed.doc_count),
                label: this.$t("Closed"),
                backgroundColor: response.data.map(() => tiffanyBlue)
              },
              {
                data: response.data.map((item) => item.open.doc_count),
                label: this.$t("Open"),
                backgroundColor: response.data.map(() => coralReef)
              }
            ],
            labels: response.data.map((item) => this.machineLookup(parseInt(item.key)).name)
          };
        }
      } catch (error) {
        console.error(error);
      }
    }
  }
};
</script>
<style lang="scss"></style>
