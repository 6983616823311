<template>
  <div class="card top-machines">
    <div class="chart-title-icons">
      <span class="header-text">{{ $t("Top Impacted Machines") }}</span>
      <div class="chart-icons">
        <v-icon
          class="bar-chart-icon"
          :color="showBarChart ? 'primary' : null"
          @click="handleShowBarChart"
        >
          mdi-chart-bar
        </v-icon>
        <v-icon
          class="tree-map-icon"
          :color="showTreeMap ? 'primary' : null"
          @click="handleShowTreeMap"
        >
          mdi-chart-tree
        </v-icon>
      </div>
    </div>
    <div class="analytics-card-header">
      <AnalyticsPagination :handleTabChange="handleTabChange" />
    </div>
    <div class="analytics-card-content">
      <TreeMapChart
        v-if="showTreeMap"
        class="tree-map"
        :data="machinesTreeMapData"
        :id="'machines-tree-map'"
        :handleClick="(event) => handleTreeMapClick(event)"
      />
      <BarChart
        v-if="showBarChart"
        class="bar-chart"
        :chartData="machinesBarChartData"
        :axisType="AXISTYPE.SHORTEN"
        :click-function="(event, clickedElements) => handleBarChartClick(event, clickedElements)"
      />
    </div>
  </div>
</template>
<script>
import AnalyticsPagination from "@/components/AnalyticsPagination";
import BarChart, { AXISTYPE } from "@/components/charts/BarChart";
import TreeMapChart from "@/components/charts/TreeMapChart";
import { tealBlue } from "@/scss/_variables.js";
import { getDatasets, handleBackgroundColorsNoOthersPresent } from "@/utils/LDMSAnalyticsUtils";
import { hexToRGB } from "@/utils/colors";

export default {
  name: "LDMSAnalyticsMachines",
  props: {
    filters: { type: Object, default: () => {} },
    interactiveFilters: { type: Object, default: () => {} }
  },
  components: {
    AnalyticsPagination,
    TreeMapChart,
    BarChart
  },
  data() {
    return {
      AXISTYPE: AXISTYPE,
      showTreeMap: true,
      showBarChart: false,
      machinesTreeMapData: [],
      machinesBarChartData: {},
      machineIds: [],
      pageSize: 10
    };
  },
  created() {
    this.getTopMachinesData();
  },
  methods: {
    handleShowBarChart() {
      this.showTreeMap = false;
      this.showBarChart = true;
    },
    handleShowTreeMap() {
      this.showBarChart = false;
      this.showTreeMap = true;
    },
    updateBackgroundColors() {
      this.machinesBarChartData.datasets[0].backgroundColor = handleBackgroundColorsNoOthersPresent(
        this.machinesBarChartData.datasets[0].backgroundColor,
        this.interactiveFilters.machines
      );
      const treeMapColors = this.machinesTreeMapData.map((data) => data.color);
      const newTreeMapColors = handleBackgroundColorsNoOthersPresent(
        treeMapColors,
        this.interactiveFilters.machines
      );
      this.machinesTreeMapData = this.machinesTreeMapData.map((data, index) => ({
        ...data,
        color: newTreeMapColors[index]
      }));
    },
    async handleTabChange(pageSize) {
      this.pageSize = pageSize;
      await this.getTopMachinesData();
      if (this.interactiveFilters.machines.length > 0) {
        this.updateBackgroundColors();
      }
    },
    handleTreeMapClick(event) {
      const elementIndex = event.target._dataItem._index;
      this.setInteractiveFilter(elementIndex);
    },
    handleBarChartClick(event, clickedElements) {
      if (clickedElements.length === 0) {
        return;
      }
      const elementIndex = clickedElements[0]._index;
      this.setInteractiveFilter(elementIndex);
    },
    setInteractiveFilter(elementIndex) {
      const machineId = this.machineIds[elementIndex];
      const foundMachineIndex = this.interactiveFilters.machines.findIndex(
        (machine) => machine.id === machineId
      );
      if (foundMachineIndex >= 0) {
        this.interactiveFilters.machines.splice(foundMachineIndex, 1);
      } else {
        this.interactiveFilters.machines.push({ id: machineId, index: elementIndex });
      }
      this.updateBackgroundColors();
    },
    async getTopMachinesData() {
      const parts = this.interactiveFilters.parts.map((part) => part.id);
      const reasons = this.interactiveFilters.reasons.map((reason) => reason.id);
      const params = {
        from_date: this.filters.fromDate,
        to_date: this.filters.toDate,
        machine_group: this.filters.machineGroups,
        machine_ids: this.filters.machines,
        part_numbers: parts.length > 0 ? parts : this.filters.parts,
        reason_ids: reasons.length > 0 ? reasons : this.filters.reasons
      };
      if (this.pageSize) {
        params.pageSize = this.pageSize;
      }
      try {
        const response = await this.$http.get("exceptions/top_machines/", { params });
        if (response && response.data) {
          this.machineIds = response.data.map((machine) => machine.machine_id);
          const colors = response.data.map((machine) => hexToRGB(tealBlue, "0.9"));
          this.machinesTreeMapData = response.data.map((machine, index) => {
            return {
              name: machine.machine_name,
              value: machine.number_of_issues,
              color: colors[index]
            };
          });
          this.machinesBarChartData = {
            labels: response.data.map((machine) => machine.machine_name),
            datasets: getDatasets(response.data, colors)
          };
        }
        Promise.resolve();
      } catch (error) {
        console.error(error);
        Promise.resolve();
      }
    }
  }
};
</script>
<style lang="scss"></style>
