<template>
  <ul>
    <li
      v-for="component in components"
      :key="component.name"
      class="mb-4"
    >
      <div
        class="component-heading"
        @click="toggle(component.name)"
      >
        <p class="text-h6 font-weight-bold text-secondary">{{ component.name }}</p>
      </div>
      <div
        v-if="isExpanded(component.name)"
        class="mt-2 ml-4"
      >
        <p v-if="component.description">
          {{ component.description }}
        </p>
        <ListComponent
          v-if="component.components"
          :components="component.components"
        />
      </div>
    </li>
  </ul>
</template>

<script setup>
import { ref } from "vue";

defineProps({
  components: {
    type: Array,
    required: true
  }
});

let expandedComponents = ref([]);

const toggle = (name) => {
  if (expandedComponents.value.includes(name)) {
    expandedComponents.value = expandedComponents.value.filter((component) => component !== name);
  } else {
    expandedComponents.value.push(name);
  }
};

const isExpanded = (name) => expandedComponents.value.includes(name);

defineExpose({ toggle, isExpanded });
</script>

<style scoped lang="scss">
.component-heading {
  cursor: pointer;
  transition: all 0.2s ease;

  &:hover {
    color: var(--v-primary-base);
  }
}
</style>
