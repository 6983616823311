import { useQuery } from "@tanstack/vue-query";
import { computed } from "vue";
import { ticketDetailsApi } from "./ticketDetailsApi";

export const useTicketDetails = (variables) => {
  const ticketDetailsQuery = useQuery({
    queryKey: ["ticket-details", variables],
    queryFn: () => ticketDetailsApi.getTicketDetails(variables.value),
    keepPreviousData: true
  });

  const ticket = computed(() => {
    if (ticketDetailsQuery?.data?.value?.data) {
      return ticketDetailsQuery.data.value.data.helpTicket;
    }
    return {};
  });

  const users = computed(() => {
    if (ticketDetailsQuery?.data?.value?.data) {
      return ticketDetailsQuery.data.value.data.users;
    }
    return [];
  });

  return {
    ...ticketDetailsQuery,
    ticket,
    users
  };
};
