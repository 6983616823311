<template>
  <div class="card full-height">
    <div class="flex-container">
      <div class="header">
        <h4>{{ `${$t("Asset Utilization By")} ${machineGroupLevelName}` }}</h4>
        <div class="icons">
          <i
            class="fa fa-table"
            v-bind:class="chartType === chartTypes.TABLE ? 'selected' : null"
            @click="
              () => {
                chartType = chartTypes.TABLE;
              }
            "
          />
          <i
            class="fa fa-bar-chart"
            v-bind:class="chartType === chartTypes.CHART ? 'selected' : null"
            @click="
              () => {
                chartType = chartTypes.CHART;
              }
            "
          />
        </div>
      </div>
      <div
        class="chart"
        v-if="chartType == chartTypes.CHART && !loading"
      >
        <BarChart
          class="chart-container"
          :chart-data="chartData"
          uom="%"
          :click-function="clickEvent"
        />
        <div class="footer">
          <div class="picker">
            <div>
              {{ $t("items per page") }}
              <select @change="sliceResult">
                <option
                  value="5"
                  :selected="numberSelected === 5"
                >
                  5
                </option>
                <option
                  value="10"
                  :selected="numberSelected === 10"
                >
                  10
                </option>
                <option
                  value="25"
                  :selected="numberSelected === 25"
                >
                  25
                </option>
                <option
                  value="50"
                  :selected="numberSelected === 50"
                >
                  50
                </option>
              </select>
            </div>
            <div>
              <i
                class="fa fa-chevron-left"
                @click="previousPage"
                v-bind:class="currentPage <= 0 && 'disabled'"
              />
              {{ `${currentPage + 1}` }} <span>of {{ numberPages }}</span>
              <i
                class="fa fa-chevron-right"
                @click="nextPage"
                v-bind:class="currentPage >= numberPages - 1 && 'disabled'"
              />
            </div>
          </div>
          <i
            class="fa sort"
            @click="toggleSort"
            v-bind:class="sortOrder === 'desc' ? 'fa-sort-amount-desc' : 'fa-sort-amount-asc'"
          />
        </div>
      </div>
      <div
        class="table"
        v-else-if="chartType == chartTypes.TABLE && !loading"
      >
        <t-l-a-utilization-table :rawData="{ ...rawData, groups: groups }" />
      </div>
      <v-progress-linear
        indeterminate
        v-else
      />
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";

import BarChart from "@/components/charts/BarChart";
import { coralReef } from "@/scss/_variables.js";
import dbCache from "@/store/modules/dbCache";

import TLAUtilizationTable from "./TLAUtilizationTable";
import useTLAFilters from "./useTLAFilters";

const chartTypes = {
  CHART: "chart",
  TABLE: "table"
};

export default {
  name: "TLAUtilizationByLevel",
  components: { TLAUtilizationTable, BarChart },
  setup() {
    const {
      interactiveFilters,
      filterApplied,
      priorityApplied,
      hasFilters,
      toggleFilter,
      removeAllFilters,
      copyFilterTo
    } = useTLAFilters();
    return {
      interactiveFilters,
      filterApplied,
      priorityApplied,
      hasFilters,
      toggleFilter,
      removeAllFilters,
      copyFilterTo
    };
  },
  data() {
    return {
      level: null,
      chartData: {},
      rawData: [],
      sortOrder: "desc",
      numberPages: 0,
      currentPage: 0,
      numberSelected: 5,
      machineGroupLevelName: "--",
      chartType: chartTypes.CHART,
      chartTypes: chartTypes,
      chartIds: [],
      filterName: "",
      loading: 0,
      cancelToken: null,
      groups: []
    };
  },
  props: ["filters", "priorityLevel", "priority"],
  computed: {
    ...mapGetters({
      machineGroups: "dbCache/machineGroups"
    })
  },
  mounted() {
    this.getReportZoneLevel()
      .then((result) => {
        this.level = result.level;
        this.filterName = this.priorityLevel;
        this.machineGroupLevelName = result.name;
        return this.loadAvailabilityForLevel();
      })
      .then((result) => {
        this.rawData = result;
        this.buildChart();
      })
      .catch((e) => {
        console.log(e);
      });
  },
  methods: {
    clickEvent(evt, clickedElements) {
      if (!clickedElements[0]) {
        return;
      }

      const element = clickedElements[0]._index;
      const id = this.chartIds[element];
      if (this.priority < this.priorityApplied) {
        this.removeAllFilters([this.filterName]);
      }

      this.toggleFilter(this.filterName, id, this.priority);
      this.buildChart();
    },
    nextPage() {
      if (this.currentPage < this.numberPages - 1) {
        this.currentPage++;
        this.buildChart();
      }
    },
    previousPage() {
      if (this.currentPage > 0) {
        this.currentPage--;
        this.buildChart();
      }
    },
    toggleSort() {
      this.sortOrder = this.sortOrder === "desc" ? "asc" : "desc";
      this.buildChart();
    },
    sliceResult(e) {
      this.numberSelected = parseInt(e.target.value);
      this.currentPage = 0;
      this.buildChart();
    },
    buildChart() {
      this.chartData = {};
      this.chartIds = [];

      const bucketsData = {};

      let { buckets } = this.rawData;
      for (const b of buckets) {
        bucketsData[b.key] = b;
      }

      let groups = [];
      const machineArray = this.filters.machines;
      const groupArray = this.filters.machine_groups;

      for (const mg of this.machineGroups) {
        if (mg.level.level == this.level) {
          const filteredMachineGroupsByMachine = mg.allMachines.filter(
            (machine) => machineArray.indexOf(machine.pk) >= 0
          );
          const allGroups = mg.allParentGroups.concat(mg.allChildGroups);
          const filteredParentGroups = allGroups.filter((group) => groupArray.indexOf(group) >= 0);

          if (
            filteredMachineGroupsByMachine.length > 0 ||
            filteredParentGroups.length > 0 ||
            (machineArray.length === 0 && groupArray.length === 0)
          ) {
            groups.push({
              id: mg.pk,
              name: mg.name
            });
          }
        }
      }

      this.chartData.datasets = [{}];
      this.chartData.datasets[0].data = [];
      this.chartData.datasets[0].label = [];
      this.chartData.datasets[0].gradients = [];

      this.chartData.title = "item.machineOrGroupName";
      this.chartData.labels = [];
      this.chartData.titles = [];

      groups.sort((a, b) => {
        const aVal = bucketsData[a.id]
          ? bucketsData[a.id].runtime.duration.value / (bucketsData[a.id].duration.value || 1)
          : 0;
        const bVal = bucketsData[b.id]
          ? bucketsData[b.id].runtime.duration.value / (bucketsData[b.id].duration.value || 1)
          : 0;
        if (this.sortOrder == "desc") {
          return bVal - aVal;
        } else {
          return aVal - bVal;
        }
      });

      this.groups = groups;

      this.numberPages = Math.ceil(groups.length / this.numberSelected);
      groups = groups.slice(
        this.numberSelected * this.currentPage,
        this.numberSelected * this.currentPage + this.numberSelected
      );

      let index = 0;
      for (const g of groups) {
        const val = bucketsData[g.id]
          ? bucketsData[g.id].runtime.duration.value / (bucketsData[g.id].duration.value || 1)
          : 0;
        this.chartData.datasets[0].data.push(100 * val.toFixed(2));
        this.chartData.datasets[0].label = this.$t("Utilization");
        this.chartData.labels.push(g.name);
        this.chartData.labels.titles = g.name;
        this.chartData.datasets[0].gradients.push([coralReef, coralReef]);
        index++;
        this.chartIds.push(g.id);
      }
    },
    loadAvailabilityForLevel() {
      return new Promise((resolve, reject) => {
        //guard
        if (!this.filters || !this.filters.from_date || !this.filters.to_date) {
          reject();
        } else {
          let params = {
            from_date: this.filters.from_date,
            to_date: this.filters.to_date,
            group_level: this.level
          };

          if (this.hasFilters) {
            if (
              this.interactiveFilters["machine_id"] &&
              this.interactiveFilters["machine_id"].length > 0
            ) {
              params.machine_id = this.interactiveFilters["machine_id"].join(",");
            } else if (this.filters.machines.length) {
              params.machine_id = this.filters.machines.join(",");
            } else if (this.filters.machine_groups.length) {
              params.machine_id = this.filters.machine_groups.join(",");
            }

            if (
              this.interactiveFilters["part_numbers"] &&
              this.interactiveFilters["part_numbers"].length > 0
            ) {
              params.part_numbers = this.interactiveFilters["part_numbers"].join(",");
            } else if (this.filters.part_numbers.length) {
              params.part_numbers = this.filters.part_numbers.join(",");
            }

            if (
              this.interactiveFilters["minor_losses"] &&
              this.interactiveFilters["minor_losses"].length > 0
            ) {
              params.status_codes = this.interactiveFilters["minor_losses"].join(",");
            } else if (
              this.interactiveFilters["major_losses"] &&
              this.interactiveFilters["major_losses"].length > 0
            ) {
              params.status_codes = this.interactiveFilters["major_losses"].join(",");
            } else if (this.filters.status_codes.length) {
              params.status_codes = this.filters.status_codes.join(",");
            }
          } else {
            params.machine_id = this.filters.machines.length
              ? this.filters.machines.join(",")
              : null;
            params.part_numbers = this.filters.part_numbers.length
              ? this.filters.part_numbers.join(",")
              : null;
            params.status_codes = this.filters.status_codes.length
              ? this.filters.status_codes.join(",")
              : null;
          }

          if (this.cancelSource) {
            this.cancelSource.cancel();
          }
          const CancelToken = axios.CancelToken;
          this.cancelSource = CancelToken.source();

          this.loading++;

          this.$http
            .get("metrics/availability_per_group_level/", {
              params: params,
              cancelToken: this.cancelSource.token
            })
            .then((result) => {
              this.loading--;
              this.cancelSource = null;
              resolve(result.data);
            })
            .catch((e) => {
              this.loading--;
              this.cancelSource = null;
              console.log(e);
            });
        }
      });
    },
    getReportZoneLevel() {
      //TODO: hook to department level
      return new Promise((resolve, reject) => {
        this.$http
          .get("graphql/", {
            params: {
              query: `{
          settings{
            ${this.priorityLevel} {
              pk,
              name,
              id,
              level
            }
          }
        }`
            }
          })
          .then((res) => {
            const tlaDepartmentLevel = res.data.data.settings[0][this.priorityLevel];
            resolve(tlaDepartmentLevel);
          })
          .catch(() => {
            reject();
          });
      });
    }
  },
  watch: {
    filters: function () {
      this.loadAvailabilityForLevel()
        .then((result) => {
          this.rawData = result;
          this.buildChart();
        })
        .catch((e) => {
          console.log(e);
        });
    },
    interactiveFilters: function () {
      if (this.filterApplied !== this.filterName && this.priority > this.priorityApplied) {
        this.loadAvailabilityForLevel()
          .then((result) => {
            this.rawData = result;
            this.buildChart();
          })
          .catch((e) => {
            console.log(e);
          });
      }
    }
  }
};
</script>

<style scoped lang="scss">
@import "../../../scss/variables";
@import "../../../scss/mq";

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex: unset;

  i {
    padding-left: 10px;
    cursor: pointer;

    &.selected {
      color: $blue;
    }
  }
}

.full-height {
  height: 100%;
  padding: 10px;
  display: flex;
  flex-direction: column;

  .flex-container {
    display: flex;
    overflow: hidden;
    flex-direction: column;
    flex: 1;
  }

  .chart {
    position: relative;
    flex: 1;
    overflow: hidden;
    padding: 10px;
    display: flex;
    flex-direction: column;

    .chart-container {
      flex: 1;
    }

    .footer {
      flex: unset;
    }

    div {
      overflow: hidden;
    }
  }

  .table {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }
}

h4 {
  color: $blue;
}

.footer {
  display: flex;
  flex: unset;
  align-items: center;
  justify-content: flex-end;

  .sort {
    padding: 0 10px;
    transform: rotate(-90deg);
    cursor: pointer;
  }

  .picker {
    font-size: 12px;
    flex: unset;
    justify-content: flex-end;
    display: flex;

    i {
      cursor: pointer;

      &.disabled {
        opacity: 0.5;
      }
    }

    div {
      padding: 0 10px;
      flex: unset;
    }

    select {
      color: white;
      text-decoration: underline;

      .theme--light & {
        color: black;
      }
      option {
        color: black;
      }
    }
  }
}
</style>
