import axios from "axios";

const TICKET_DETAILS_QUERY = `query getByID($id: ID!){
  helpTicket(id: $id){
    id,
    ... on HelpTicketSearch{
      id,
      openedDate,
      openedBy,
      openedByName,
      ticketedDate,
      ticketedBy,
      ticketedByName,
      firstResponseDate,
      firstResponseBy,
      firstResponseByName,
      closedDate,
      closedBy,
      closedByName,
      factoryOrder,
      machineId,
      machineName,
      partId,
      partName,
      assetId,
      assetName,
      details,
      title,
      reference,
      tagSearch,
      assignedTo,
      assignedToNames,
      responding,
      respondingNames,
      solutionType,
      solutionDetails
      issues{
        id
        name
        color
      }
    }
  }
  users{
    id,
    firstName,
    lastName,
  }
}`;

const TICKET_DETAILS_SUBSCRIBE_MUTATION = `mutation ($id: ID!, $helpTicketData: HelpUpdateInput!){
  updateHelpTicket(globalId:$id, helpData:$helpTicketData){
    ok,
    helpTicket{
      reference
    }
  }
}`;

export const ticketDetailsApi = {
  getTicketDetails: (variables) =>
    axios.post("/graphql/", { query: TICKET_DETAILS_QUERY, variables }).then((res) => res.data),
  setTicketDetailsSubscription: (variables) =>
    axios
      .post("/graphql/", { query: TICKET_DETAILS_SUBSCRIBE_MUTATION, variables })
      .then((res) => res.data),
  getTicketDetailsSimilarSolution: (params) =>
    axios.post("/help/semantic/", { ...params }).then((res) => res.data)
};
