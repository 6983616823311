<template>
  <div class="card status-data">
    <p class="title">{{ $t("Status") }}</p>
    <PieChart
      :chartData="chartData"
      :chartOptions="statusChartOptions"
      :backgroundColors="statusChartBackgroundColors"
    />
  </div>
</template>
<script>
import PieChart from "@/components/charts/PieChart";
import { coralReef, tiffanyBlue } from "@/scss/_variables.js";

export default {
  name: "P911OpenClosedPieChart",
  props: {
    statusChartData: { type: Object, default: () => ({ datasets: [{ data: [] }], labels: [] }) }
  },
  components: {
    PieChart
  },
  data() {
    return {
      statusChartOptions: {
        tooltips: {
          callbacks: {
            label: (item, data) =>
              data["labels"][item["index"]] + ": " + data["datasets"][0]["data"][item["index"]]
          }
        },
        maintainAspectRatio: false
      },
      statusChartBackgroundColors: [tiffanyBlue, coralReef],
      chartData: { datasets: [{ data: [] }], labels: [] }
    };
  },
  watch: {
    statusChartData: {
      handler() {
        this.chartData = this.statusChartData;
      },
      deep: true
    }
  }
};
</script>
