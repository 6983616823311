import { useQuery } from "@tanstack/vue-query";
import { ticketDetailsApi } from "./ticketDetailsApi";

export const useTicketDetailsSimilarSolution = (variables, open) => {
  const ticketDetailsSimilarSolutionQuery = useQuery({
    queryKey: ["ticket-details-similar-solution", variables],
    queryFn: () => ticketDetailsApi.getTicketDetailsSimilarSolution(variables.value),
    keepPreviousData: true,
    enabled: open
  });

  return ticketDetailsSimilarSolutionQuery;
};
