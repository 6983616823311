export const env = {
  apiBaseUrl: process.env.API_BASE_URL || "/api",
  DFBaseUrl: process.env.API_BASE_URL.replace("/api", "/df") || "/df",
  IOTBaseUrl: process.env.API_BASE_URL.replace("/api", "/iotAPI") || "/iotAPI",
  wsBaseUrl: process.env.WS_BASE_URL ? process.env.WS_BASE_URL : window.location.host + "/api",
  wsConversationUrl: process.env.WS_CONVERSATION_URL
    ? process.env.WS_CONVERSATION_URL
    : "wss://openai-iris-batch-001.dev.intelycx.com/ws",
  enableDocs: process.env.ENABLE_DOCUMENTATION ? process.env.ENABLE_DOCUMENTATION : false
};
