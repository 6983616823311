<template>
  <!--
    https://intelycx.atlassian.net/browse/MIM-4701
    Enable Giant Screen only for summary pages
  -->
  <component
    is="style"
    v-if="isUseGiant"
    type="text/css"
  >
    @media (min-width: 1981px) { html { font-size: 1vw; } }
  </component>
</template>
<script>
import { AppRoutes } from "@/shared/app-routes";

export default {
  computed: {
    name() {
      return this.$route.name;
    },
    isUseGiant() {
      return (
        this.$route.name === AppRoutes.machineSummary.name ||
        this.name === AppRoutes.groupSummary.name ||
        this.$route.name === "login"
      );
    }
  }
};
</script>
