<template>
  <div class="availabilityHourByHourTable">
    <h2 class="card-title">{{ $t("Availability") }}</h2>
    <generic-table
      :columns="[
        { title: $t('Time'), key: 'time' },
        { title: $t('Part Number'), key: 'part_number' },
        { title: $t('Target'), key: 'target' },
        { title: $t('Actual'), key: 'actual' }
      ]"
      :nested_columns="[{ key: '' }, { key: 'part_number' }, { key: 'target' }, { key: 'actual' }]"
      :footer="[
        { type: 'csv', csv: downloadCsv },
        { type: 'text', text: $t('Total') },
        { type: 'text', text: this.seconds(totalTarget) },
        { type: 'text', text: this.seconds(totalRunning) }
      ]"
      :data="tableData"
    />
  </div>
</template>

<script>
import { Fragment } from "vue-fragment";
import { mapGetters } from "vuex";

import GenericTable from "@/components/GenericTable";
import createCSVFromRows from "@/utils/createCSVFromRows";
import { seconds } from "@/utils/filters";
import { sumValue } from "@/utils/sumValue";

export default {
  name: "AvailabilityHourByHour.vue",
  components: {
    GenericTable,
    Fragment
  },
  data() {
    return {
      totalTarget: 0,
      totalRunning: 0,

      hoursKeys: [],
      partsHoursTopLevel: {},
      partsHoursNested: {},
      tableData: []
    };
  },
  computed: {
    ...mapGetters({
      statuses: "app/Statuses",
      theme: "app/Theme"
    })
  },
  props: ["chartOptions", "production"],
  methods: {
    seconds,
    sumValue,
    createCSVFromRows,
    downloadCsv() {
      const rows = [];
      rows.push([
        this.$t("Time"),
        this.$t("Timestamp"),
        this.$t("Part Number"),
        this.$t("Target (Mins)"),
        this.$t("Actual (Mins)")
      ]);
      for (const hk of this.hoursKeys) {
        const nested = this.partsHoursNested[hk];
        for (const n of nested) {
          rows.push([
            this.chartOptions.labelFromString(n.time),
            n.time_string,
            n.part_number,
            Math.round(n.target / 60),
            Math.round(n.running / 60)
          ]);
        }
      }
      this.createCSVFromRows(rows, `availability_${new Date().toISOString()}`);
    },
    expandParts(hour) {
      this.partsHoursTopLevel[hour].expanded = !this.partsHoursTopLevel[hour].expanded;
      this.hoursKeys = [...this.hoursKeys];
    },
    renderPartsLabel(parts) {
      if (parts.length > 1) {
        return `${parts[0]} +${parts.length - 1} more`;
      }
      return parts[0];
    }
  },
  created() {
    const partsHoursList = [];
    //TODO: store status map in the statuses app store in the future.
    const statusMap = {};
    for (const status of this.statuses) {
      const plannedDown = status.plannedDown;
      const running = status.running;
      for (const set of status.statuscodeSet) {
        statusMap[set.code] = {
          running: running,
          plannedDown: plannedDown,
          ...set
        };
      }
    }

    const partsHours = {};
    for (const interval of this.production) {
      for (const machine of interval.by_machine) {
        for (const part of machine.by_part) {
          if (partsHours[part.part.part_number] == null) {
            partsHours[part.part.part_number] = {};

            partsHours[part.part.part_number][interval.key] = {
              running: 0,
              total: 0,
              target: 0,
              part_number: part.part.part_number,
              time: interval.key,
              time_string: interval.key_as_string
            };
          } else if (!partsHours[part.part.part_number][interval.key]) {
            partsHours[part.part.part_number][interval.key] = {
              running: 0,
              total: 0,
              target: 0,
              part_number: part.part.part_number,
              time: interval.key,
              time_string: interval.key_as_string
            };
          }
          partsHours[part.part.part_number][interval.key].target += part.runtime_target;
          for (const status_codes of part.status_codes) {
            if (
              statusMap[status_codes.status_code] &&
              statusMap[status_codes.status_code].running == true
            ) {
              partsHours[part.part.part_number][interval.key].running += status_codes.duration;
            }
            partsHours[part.part.part_number][interval.key].total += status_codes.duration;
          }
        }
      }
    }

    for (const part of Object.keys(partsHours)) {
      for (const interval of Object.keys(partsHours[part])) {
        partsHoursList.push(partsHours[part][interval]);
      }
    }

    this.partsHoursTopLevel = {};
    this.partsHoursNested = {};

    for (const p of partsHoursList) {
      if (!this.partsHoursNested[p.time]) {
        this.partsHoursNested[p.time] = [];
      }
      this.partsHoursNested[p.time].push(p);

      if (!this.partsHoursTopLevel[p.time]) {
        this.partsHoursTopLevel[p.time] = {
          part_number: [],
          running: 0,
          target: 0,
          time: p.time,
          total: 0,
          expanded: false
        };
      }
      this.partsHoursTopLevel[p.time].running += p.running;
      this.partsHoursTopLevel[p.time].target += p.target;
      this.partsHoursTopLevel[p.time].total += p.total;
      this.partsHoursTopLevel[p.time].part_number.push(p.part_number);
    }

    this.hoursKeys = Object.keys(this.partsHoursTopLevel).sort();

    this.totalRunning = sumValue(partsHoursList, ["running"]);
    this.totalTarget = sumValue(partsHoursList, ["target"]);
  },
  watch: {
    hoursKeys: function () {
      const list = [];
      this.hoursKeys.forEach((hour, idx) => {
        const l = {
          time: this.chartOptions.labelFromString(this.partsHoursTopLevel[hour].time),
          part_number: this.renderPartsLabel(this.partsHoursTopLevel[hour].part_number),
          target: this.seconds(this.partsHoursTopLevel[hour].target),
          actual: this.seconds(this.partsHoursTopLevel[hour].running),
          _nested: []
        };
        this.partsHoursNested[hour].forEach((p) => {
          l._nested.push({
            time: "",
            part_number: p.part_number,
            target: this.seconds(p.target),
            actual: seconds(p.running)
          });
        });

        list.push(l);
      });

      this.tableData = list;
    }
  }
};
</script>

<style lang="scss">
@import "../../../scss/variables";
@import "../../../scss/mq";

.availabilityHourByHourTable {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: hidden;

  .expand-icon {
    padding: 5px;
    transition: transform 0.25s;
    cursor: pointer;

    &.hidden {
      visibility: hidden;
    }
  }

  .rotated {
    transform: rotate(90deg);
  }

  thead {
    tr {
      white-space: nowrap;
    }
  }

  tbody {
    overflow: unset;
  }

  td {
    text-align: left !important;
  }

  .badColor {
    background: $red;
  }

  .goodColor {
    background: $green;
  }

  tfoot {
    position: sticky;
    bottom: 0;

    .theme--dark & {
      background: $grey;
    }

    .theme--light & {
      background: $light-background;
    }
  }

  .rowPart {
    .theme--dark & {
      background: $darkGrey;
    }
  }
}
</style>
