import { AppRoutes } from "@/shared/app-routes";
import { ROUTES } from "@/features/zone-control/constants";

const metricTabsValue = {
  KPI: "0",
  HourByHour: "1"
};

export const REDIRECT_ROUTES_OLD_TO_NEW = [
  {
    route: AppRoutes.machine.name,
    getRoute: (params, query) => {
      if (query.metricTab && query.metricTab.toString() === metricTabsValue.KPI) {
        return AppRoutes.machineKpi.url(params.machine_id, params.machine_pk);
      } else if (query.metricTab && query.metricTab.toString() === metricTabsValue.HourByHour) {
        return AppRoutes.machineHour.url(params.machine_id, params.machine_pk);
      }
      return AppRoutes.machineKpi.url(params.machine_id, params.machine_pk);
    }
  },
  {
    route: AppRoutes.groupRealtime.name,
    getRoute: (params) => {
      return AppRoutes.groupSummary.url(
        params.level,
        params.machine_group_id,
        params.machine_group_pk
      );
    }
  },
  {
    route: AppRoutes.groupSummaryOld.name,
    getRoute: (params) => {
      // return AppRoutes.groupStatus.url(
      //   params.level,
      //   params.machine_group_id,
      //   params.machine_group_pk
      // );
      return AppRoutes.groupSummary.url(
        params.level,
        params.machine_group_id,
        params.machine_group_pk
      );
    }
  },
  {
    route: AppRoutes.groupQuality.name,
    getRoute: (params) => {
      // return AppRoutes.qualityEventsGroup.url(
      //   params.level,
      //   params.machine_group_id,
      //   params.machine_group_pk
      // );
      return AppRoutes.groupSummary.url(
        params.level,
        params.machine_group_id,
        params.machine_group_pk
      );
    }
  },
  {
    route: AppRoutes.groupZoneControl.name,
    getRoute: (params) => {
      // return AppRoutes.groupZoneControlNew.url(
      //   params.level,
      //   params.machine_group_id,
      //   params.machine_group_pk
      // );
      return AppRoutes.groupSummary.url(
        params.level,
        params.machine_group_id,
        params.machine_group_pk
      );
    }
  },
  {
    route: AppRoutes.groupTickets.name,
    getRoute: (params) => {
      // return AppRoutes.groupTicketsP911.url(
      //   params.level,
      //   params.machine_group_id,
      //   params.machine_group_pk
      // );
      return AppRoutes.groupTicketsP911.url(
        params.level,
        params.machine_group_id,
        params.machine_group_pk
      );
    }
  }
];

export const REDIRECT_ROUTES_NEW_TO_OLD = [
  {
    route: AppRoutes.machineKpi.name,
    getRoute: (params) => {
      return (
        AppRoutes.machine.url(params.machine_id, params.machine_pk) +
        `?metricTab=${metricTabsValue.KPI}`
      );
    }
  },
  {
    route: AppRoutes.machineHour.name,
    getRoute: (params) => {
      // return (
      //   AppRoutes.machine.url(params.machine_id, params.machine_pk) +
      //   `?metricTab=${metricTabsValue.HourByHour}`
      // );
      return (
        AppRoutes.machine.url(params.machine_id, params.machine_pk) +
        `?metricTab=${metricTabsValue.HourByHour}`
      );
    }
  },
  {
    route: AppRoutes.machineSummary.name,
    getRoute: (params) => {
      return (
        AppRoutes.machine.url(params.machine_id, params.machine_pk) +
        `?metricTab=${metricTabsValue.KPI}`
      );
    }
  },
  {
    route: AppRoutes.machineAnomalies.name,
    getRoute: (params) => {
      return (
        AppRoutes.machine.url(params.machine_id, params.machine_pk) +
        `?metricTab=${metricTabsValue.KPI}`
      );
    }
  },
  // {
  //   route: AppRoutes.operatorDashboard.name,
  //   getRoute: (params) => {
  //     return (
  //       AppRoutes.machine.url(params.machine_id, params.machine_pk) +
  //       `?metricTab=${metricTabsValue.KPI}`
  //     );
  //   }
  // },
  {
    // route: AppRoutes.groupStatus.name,
    route: AppRoutes.groupSummary.name,
    getRoute: (params) => {
      return AppRoutes.groupSummaryOld.url(
        params.level,
        params.machine_group_id,
        params.machine_group_pk
      );
    }
  },
  {
    route: AppRoutes.groupStatus.name,
    // route: AppRoutes.groupSummary.name,
    getRoute: (params) => {
      return AppRoutes.groupSummaryOld.url(
        params.level,
        params.machine_group_id,
        params.machine_group_pk
      );
    }
  },
  {
    route: AppRoutes.machineEvents.name,
    getRoute: (params) => {
      return (
        AppRoutes.machine.url(params.machine_id, params.machine_pk) +
        `?metricTab=${metricTabsValue.KPI}`
      );
    }
  },
  {
    route: AppRoutes.qualityEventsMachine.name,
    getRoute: (params) => {
      // return AppRoutes.groupQuality.url(
      //   params.level,
      //   params.machine_group_id,
      //   params.machine_group_pk
      // );
      return (
        AppRoutes.machine.url(params.machine_id, params.machine_pk) +
        `?metricTab=${metricTabsValue.KPI}`
      );
    }
  },
  {
    route: AppRoutes.processMonitoring.name,
    getRoute: (params) => {
      return (
        AppRoutes.machine.url(params.machine_id, params.machine_pk) +
        `?metricTab=${metricTabsValue.KPI}`
      );
    }
  },
  {
    route: AppRoutes.machineTickets.name,
    getRoute: (params) => {
      return (
        AppRoutes.machine.url(params.machine_id, params.machine_pk) +
        `?metricTab=${metricTabsValue.KPI}`
      );
    }
  },
  {
    route: AppRoutes.machineTicketsP911.name,
    getRoute: (params) => {
      return (
        AppRoutes.machine.url(params.machine_id, params.machine_pk) +
        `?metricTab=${metricTabsValue.KPI}`
      );
    }
  },
  {
    route: AppRoutes.machineTicketsLdms.name,
    getRoute: (params) => {
      return (
        AppRoutes.machine.url(params.machine_id, params.machine_pk) +
        `?metricTab=${metricTabsValue.KPI}`
      );
    }
  },
  {
    route: AppRoutes.qualityEventsGroup.name,
    getRoute: (params) => {
      // return AppRoutes.groupQuality.url(
      //   params.level,
      //   params.machine_group_id,
      //   params.machine_group_pk
      // );
      return AppRoutes.groupSummaryOld.url(
        params.level,
        params.machine_group_id,
        params.machine_group_pk
      );
    }
  },
  {
    route: AppRoutes.groupZoneControlNew.name,
    getRoute: (params) => {
      // return AppRoutes.groupZoneControl.url(
      //   params.level,
      //   params.machine_group_id,
      //   params.machine_group_pk
      // );
      return AppRoutes.groupSummaryOld.url(
        params.level,
        params.machine_group_id,
        params.machine_group_pk
      );
    }
  },
  {
    route: ROUTES.PROBLEM_SOLVING,
    getRoute: (params) => {
      // return AppRoutes.groupZoneControl.url(
      //   params.level,
      //   params.machine_group_id,
      //   params.machine_group_pk
      // );
      return AppRoutes.groupSummaryOld.url(
        params.level,
        params.machine_group_id,
        params.machine_group_pk
      );
    }
  },
  {
    route: ROUTES.WEEKLY_PRODUCTION_SUMMARY,
    getRoute: (params) => {
      // return AppRoutes.groupZoneControl.url(
      //   params.level,
      //   params.machine_group_id,
      //   params.machine_group_pk
      // );
      return AppRoutes.groupSummaryOld.url(
        params.level,
        params.machine_group_id,
        params.machine_group_pk
      );
    }
  },
  {
    route: ROUTES.TRENDS,
    getRoute: (params) => {
      // return AppRoutes.groupZoneControl.url(
      //   params.level,
      //   params.machine_group_id,
      //   params.machine_group_pk
      // );
      return AppRoutes.groupSummaryOld.url(
        params.level,
        params.machine_group_id,
        params.machine_group_pk
      );
    }
  },
  {
    route: AppRoutes.groupTicketsNew.name,
    getRoute: (params) => {
      // return AppRoutes.groupTickets.url(
      //   params.level,
      //   params.machine_group_id,
      //   params.machine_group_pk
      // );
      return AppRoutes.groupSummaryOld.url(
        params.level,
        params.machine_group_id,
        params.machine_group_pk
      );
    }
  },
  {
    route: AppRoutes.groupTicketsP911.name,
    getRoute: (params) => {
      // return AppRoutes.groupTickets.url(
      //   params.level,
      //   params.machine_group_id,
      //   params.machine_group_pk
      // );
      return AppRoutes.groupSummaryOld.url(
        params.level,
        params.machine_group_id,
        params.machine_group_pk
      );
    }
  },
  {
    route: AppRoutes.groupTicketsLdms.name,
    getRoute: (params) => {
      // return AppRoutes.groupTickets.url(
      //   params.level,
      //   params.machine_group_id,
      //   params.machine_group_pk
      // );
      return AppRoutes.groupSummaryOld.url(
        params.level,
        params.machine_group_id,
        params.machine_group_pk
      );
    }
  }
];
