<template>
  <ModalDialog
    v-model="visibleProp"
    content-class="pin-dialog"
    max-width="576px"
    height="416px"
    full-screen-mobile
    persistent
  >
    <template #title>
      {{ $t("Action Login") }}
    </template>

    <template #body>
      <div v-if="!loginError">
        <p class="text-subtitle-2 text--secondary font-weight-regular">
          {{ $t("Fill in your information to respond to this ticket") }}
        </p>
        <div class="mt-4">
          <v-autocomplete
            v-model="username"
            :items="users"
            :loading="!loadedUsers"
            :menu-props="{
              closeOnClick: true,
              closeOnContentClick: true
            }"
            item-text="Username"
            :label="$t('User')"
            outlined
            hide-details
          />
        </div>

        <div class="mt-6 mt-sm-4">
          <v-text-field
            v-model="pin"
            class="pin-input"
            :label="$t('PIN')"
            outlined
            @input="typeNumbersOnly"
          />
        </div>
      </div>

      <div v-else>
        <div class="loginPadding loginDialog">
          {{ loginError }}
        </div>
      </div>
    </template>

    <template #actions>
      <Fragment v-if="loginError">
        <Btn
          class="pin-btn"
          @click="loginError = null"
          >{{ $t("Ok") }}</Btn
        >
      </Fragment>
      <Fragment v-else>
        <Btn
          class="pin-btn"
          primary
          @click="$emit('update:visibleProp', false)"
          >{{ $t("Cancel") }}</Btn
        >
        <Btn
          class="pin-btn ml-3"
          variant="primary"
          @click="tryLogin"
          >{{ $t("Login") }}</Btn
        >
      </Fragment>
    </template>
  </ModalDialog>
</template>

<script>
import { Fragment } from "vue-fragment";
import Btn from "@/ui/Btn/Btn.vue";
import DialogCloseButton from "@/components/DialogCloseButton";
import PinPad from "./PinPad";
import ModalDialog from "@/ui/ModalDialog/ModalDialog.vue";

export default {
  props: ["loginCallback", "visibleProp", "userRole", "callbackData"],
  components: { Fragment, ModalDialog, PinPad, DialogCloseButton, Btn },
  data() {
    return {
      loginBlock: false,
      loginError: null,
      pin: "",
      username: "",
      users: [],
      loadedUsers: false
    };
  },
  watch: {
    visibleProp: function (v) {
      if (v) {
        if (this.userRole) {
          this.getUsers("Machine Admin");
        } else {
          this.getUsers();
        }
      }
      this.$emit("update:visibleProp", v);
    }
  },
  methods: {
    tryLogin: function () {
      this.loginBlock = true;
      this.$http
        .post("pinlogin", { username: this.username, pin: this.pin })
        .then((res) => {
          this.loginBlock = false;
          this.$emit("update:visibleProp", false);
          this.loginCallback(res.data.token, this.callbackData, this.username);
        })
        .catch((e) => {
          this.loginBlock = false;
          this.loginError = this.$t("Incorrect Username/Password");
        });
    },
    pinCallback(v) {
      this.pin = v;
    },
    getUsers(role) {
      let query;
      if (role) {
        query = `query{
        users(role:"${role}"){
            username
        }
      }`;
      } else {
        query = `query{
        users{
            username
        }
      }`;
      }
      let variables = {
        order: {
          username: "ASC"
        }
      };

      this.$http.post("graphql/", { query, variables }).then((res) => {
        if (res.data.data.users) {
          this.users = res.data.data.users.map((u) => u.username);
        }
        this.loadedUsers = true;
      });
    },
    typeNumbersOnly() {
      const numbersPin = this.pin.replace(/\D/g, "");
      this.$nextTick(() => {
        this.pin = numbersPin;
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.pin-btn {
  min-width: 168px !important;

  @media (max-width: map-get($grid-breakpoints, "sm")) {
    --padding: 6px;

    min-width: 0 !important;
    width: calc(50% - var(--padding));
    flex-grow: 1;

    &:first-of-type {
      margin-right: var(--padding) !important;
    }

    &:last-of-type {
      margin-left: var(--padding) !important;
    }

    &::v-deep.v-btn.button {
      height: 52px;
    }
  }
}
</style>

<style lang="scss">
.pin-dialog {
  .body {
    @media (max-width: map-get($grid-breakpoints, "sm")) {
      padding-top: 4px !important;
    }
  }
}

.pin-input {
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
  }
}
</style>
