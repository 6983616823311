<template>
  <ModalDialog
    :value="open"
    persistent
    max-width="400px"
    height="256px"
    @close="$emit('close')"
  >
    <template #title> {{ $t("Respond") }} </template>

    <template #body>
      <div class="text-subtitle-1 text--primary">
        {{ $t("Are you sure you want to respond to the ticket?") }}
      </div>
    </template>
    <template #actions>
      <Btn
        class="btn-respond"
        primary
        @click="$emit('close')"
      >
        {{ $t("Cancel") }}
      </Btn>
      <Btn
        class="btn-respond"
        variant="primary"
        :loading="loading"
        @click="$emit('submit')"
      >
        {{ $t("Respond") }}
      </Btn>
    </template>
  </ModalDialog>
</template>

<script setup>
import Btn from "@/ui/Btn/Btn.vue";
import ModalDialog from "@/ui/ModalDialog/ModalDialog.vue";

defineProps({
  ticket: {
    type: Object,
    default: () => ({})
  },
  open: {
    type: Boolean,
    default: false,
    required: true
  },
  loading: { type: Boolean, default: false, required: true }
});

defineEmits(["close", "submit"]);
</script>

<style scoped lang="scss">
@import "@/scss/_variables.scss";

.btn-respond {
  min-width: 168px !important;

  @media (max-width: map-get($grid-breakpoints, "sm")) {
    --padding: 6px;

    min-width: 0 !important;
    width: calc(50% - var(--padding));
    flex-grow: 1;

    &:first-of-type {
      margin-right: var(--padding) !important;
    }

    &:last-of-type {
      margin-left: var(--padding) !important;
    }

    &::v-deep.v-btn.button {
      height: 52px;
    }
  }
}
</style>
