<template>
  <div class="card full-height">
    <div class="flex-container">
      <div class="header">
        <h4>{{ `${$t("Asset Utilization By Machine")}` }}</h4>
        <div class="icons">
          <i
            class="fa fa-table"
            v-bind:class="chartType === chartTypes.TABLE ? 'selected' : null"
            @click="
              () => {
                chartType = chartTypes.TABLE;
              }
            "
          />
          <i
            class="fa fa-bar-chart"
            v-bind:class="chartType === chartTypes.CHART ? 'selected' : null"
            @click="
              () => {
                chartType = chartTypes.CHART;
              }
            "
          />
        </div>
      </div>
      <div
        class="chart"
        v-if="chartType == chartTypes.CHART && !loading"
      >
        <BarChart
          class="chart-container"
          :chart-data="chartData"
          uom="%"
          :click-function="clickEvent"
        />
        <div class="footer">
          <bar-chart-paginator
            :refresh="interactiveFilters"
            :rawData="rawData"
            :sortMethod="sortByDurationPercent"
            default-sort="desc"
            :buildChart="buildChart"
          />
        </div>
      </div>
      <div
        class="table"
        v-else-if="chartType == chartTypes.TABLE && !loading"
      >
        <t-l-a-utilization-table :rawData="rawData" />
      </div>
      <v-progress-linear
        indeterminate
        v-else
      />
    </div>
  </div>
</template>

<script>
import axios from "axios";

import BarChart from "@/components/charts/BarChart";
import { tealBlue } from "@/scss/_variables.js";

import BarChartPaginator from "../../../components/BarChartPaginator";
import TLAUtilizationTable from "./TLAUtilizationTable";
import useTLAFilters from "./useTLAFilters";

const chartTypes = {
  CHART: "chart",
  TABLE: "table"
};

export default {
  name: "TLAUtilizationMachine",
  components: { BarChartPaginator, TLAUtilizationTable, BarChart },
  setup() {
    const {
      interactiveFilters,
      filterApplied,
      priorityApplied,
      hasFilters,
      toggleFilter,
      removeAllFilters,
      copyFilterTo
    } = useTLAFilters();
    return {
      interactiveFilters,
      filterApplied,
      priorityApplied,
      hasFilters,
      toggleFilter,
      removeAllFilters,
      copyFilterTo
    };
  },
  data() {
    return {
      level: null,
      chartData: {},
      rawData: [],
      machineGroupLevelName: "--",
      chartType: chartTypes.CHART,
      chartTypes: chartTypes,
      chartIds: [],
      filterName: "machine_id",
      loading: 0,
      cancelToken: null
    };
  },
  props: ["filters", "priorityLevels", "priority"],
  mounted() {
    this.loadMachineAvailability()
      .then((result) => {
        this.rawData = this.createRawData(result);
      })
      .catch((e) => {
        console.log(e);
      });
  },
  methods: {
    clickEvent(evt, clickedElements) {
      if (!clickedElements[0]) {
        return;
      }

      const element = clickedElements[0]._index;
      const id = this.chartIds[element];
      if (this.priority < this.priorityApplied) {
        this.removeAllFilters(["tlaReportMachineGroupLevelP2", "tlaReportMachineGroupLevelP2"]);
      }

      this.toggleFilter(this.filterName, id, this.priority);
    },
    createRawData(result) {
      if (!result) {
        return [];
      }

      const { keys, buckets } = result;
      const bucketsByKey = {};
      const rawData = [];
      for (const b of buckets) {
        bucketsByKey[b.key] = b;
      }
      for (let g of keys) {
        rawData.push({ ...g, ...bucketsByKey[g.id] });
      }

      return rawData;
    },
    sortByDurationPercent: function (rawData, sortOrder) {
      rawData.sort((a, b) => {
        const aVal =
          a.runtime && a.duration ? a.runtime.duration.value / (a.duration.value || 1) : 0;
        const bVal =
          b.runtime && b.duration ? b.runtime.duration.value / (b.duration.value || 1) : 0;
        if (sortOrder == "desc") {
          return bVal - aVal;
        } else {
          return aVal - bVal;
        }
      });
      return rawData;
    },
    buildChart(slicedData) {
      this.chartData = {};
      this.chartIds = [];

      this.chartData.datasets = [{}];
      this.chartData.datasets[0].data = [];
      this.chartData.datasets[0].label = [];
      this.chartData.datasets[0].gradients = [];

      this.chartData.title = "item.machineOrGroupName";
      this.chartData.labels = [];
      this.chartData.titles = [];

      let index = 0;
      for (const s of slicedData || []) {
        const val = s.runtime ? s.runtime.duration.value / (s.duration.value || 1) : 0;
        this.chartData.datasets[0].data.push(100 * val.toFixed(2));
        this.chartData.datasets[0].label = this.$t("Utilization");
        this.chartData.labels.push(s.name);
        this.chartData.labels.titles = s.name;
        this.chartData.datasets[0].gradients.push([tealBlue, tealBlue]);
        index++;
        this.chartIds.push(s.id);
      }
    },
    loadMachineAvailability() {
      return new Promise((resolve, reject) => {
        //guard
        if (!this.filters || !this.filters.from_date || !this.filters.to_date) {
          reject();
        } else {
          let params = {
            from_date: this.filters.from_date,
            to_date: this.filters.to_date
          };

          if (this.hasFilters) {
            if (
              this.interactiveFilters["machine_id"] &&
              this.interactiveFilters["machine_id"].length > 0
            ) {
              params.machine_id = this.interactiveFilters["machine_id"].join(",");
            } else if (this.filters.machines.length) {
              params.machine_id = this.filters.machines.join(",");
            } else if (this.filters.machine_groups.length) {
              params.machine_id = this.filters.machine_groups.join(",");
            }

            if (
              this.interactiveFilters["part_numbers"] &&
              this.interactiveFilters["part_numbers"].length > 0
            ) {
              params.part_numbers = this.interactiveFilters["part_numbers"].join(",");
            } else if (this.filters.part_numbers.length) {
              params.part_numbers = this.filters.part_numbers.join(",");
            }

            if (
              this.interactiveFilters["minor_losses"] &&
              this.interactiveFilters["minor_losses"].length > 0
            ) {
              params.status_codes = this.interactiveFilters["minor_losses"].join(",");
            } else if (
              this.interactiveFilters["major_losses"] &&
              this.interactiveFilters["major_losses"].length > 0
            ) {
              params.status_codes = this.interactiveFilters["major_losses"].join(",");
            } else if (this.filters.status_codes.length) {
              params.status_codes = this.filters.status_codes.join(",");
            }
          } else {
            params.machine_id = this.filters.machines.length
              ? this.filters.machines.join(",")
              : null;
            params.part_numbers = this.filters.part_numbers.length
              ? this.filters.part_numbers.join(",")
              : null;
            params.status_codes = this.filters.status_codes.length
              ? this.filters.status_codes.join(",")
              : null;
          }

          if (this.cancelSource) {
            this.cancelSource.cancel();
          }
          const CancelToken = axios.CancelToken;
          this.cancelSource = CancelToken.source();

          this.loading++;

          this.$http
            .get("metrics/availability_per_machine/", {
              params: params,
              cancelToken: this.cancelSource.token
            })
            .then((result) => {
              this.loading--;
              this.cancelSource = null;
              resolve(result.data);
            })
            .catch((e) => {
              this.loading--;
              this.cancelSource = null;
              console.log(e);
            });
        }
      });
    }
  },
  watch: {
    filters: function () {
      this.loadMachineAvailability()
        .then((result) => {
          this.rawData = this.createRawData(result);
        })
        .catch((e) => {
          console.log(e);
        });
    },
    interactiveFilters: function () {
      if (this.filterApplied !== this.filterName && this.priority > this.priorityApplied) {
        this.loadMachineAvailability()
          .then((result) => {
            this.rawData = this.createRawData(result);
          })
          .catch((e) => {
            console.log(e);
          });
      }
    }
  }
};
</script>

<style scoped lang="scss">
@import "../../../scss/variables";
@import "../../../scss/mq";

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex: unset;

  i {
    padding-left: 10px;
    cursor: pointer;

    &.selected {
      color: $blue;
    }
  }
}

.full-height {
  height: 100%;
  padding: 10px;
  display: flex;
  flex-direction: column;

  .flex-container {
    display: flex;
    overflow: hidden;
    flex-direction: column;
    flex: 1;
  }

  .chart {
    position: relative;
    flex: 1;
    overflow: hidden;
    padding: 10px;
    display: flex;
    flex-direction: column;

    .chart-container {
      flex: 1;
    }

    .footer {
      flex: unset;
    }

    div {
      overflow: hidden;
    }
  }

  .table {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }
}

h4 {
  color: $blue;
}

.footer {
  display: flex;
  flex: unset;
  align-items: center;
  justify-content: flex-end;
}
</style>
