<template>
  <div class="py-6 px-5 px-md-6 d-flex flex-column page">
    <div class="text-body-2 font-weight-medium mb-4">
      <a @click.prevent="backToTickets">{{ $t("Tickets") }}</a>
      <span class="mx-1 font-weight-regular">/</span>
      <span class="">{{ ticket?.machineName }}</span>
    </div>

    <div class="composition">
      <div class="ticket-header d-flex align-center mb-1">
        <h1 class="text-h5 font-weight-medium">{{ ticket?.machineName }}</h1>

        <!-- TODO: Add edit and similar solutions functionality -->
        <div class="ml-auto">
          <!-- <Btn
          large

          ><v-icon
            size="24"
            class="mr-1"
            >mdi-pencil-outline</v-icon
          >{{ $t("Edit") }}</Btn
        > -->
          <Btn
            large
            class="ml-3"
            @click="showSolutionDialog = true"
          >
            <v-icon
              size="24"
              class="mr-1"
            >
              mdi-arrow-decision-outline
            </v-icon>
            {{ $t("Similar solutions") }}
          </Btn>
        </div>
      </div>

      <div class="ticket-body mb-10">
        <div class="type text-body-1 color--text-secondary mb-3">
          <span>{{ $t("Category") }}</span>
          <span>
            <v-chip
              v-if="categoryIssue"
              :color="categoryIssue?.color"
              class="ticket-issue color--text-contrast text-subtitle-1 py-1 px-2"
              label
            >
              {{ categoryIssue?.name }}
            </v-chip>
          </span>

          <span class="text-subtitle-1 color--text-primary">{{ $t("Issue") }}</span>
          <span>
            <v-chip
              v-for="(issue, issueIndex) in qualityIssues"
              :key="issueIndex"
              :color="issue.color"
              class="ticket-issue color--text-contrast text-subtitle-1 py-1 px-2"
              label
            >
              {{ issue.name }}
            </v-chip>
          </span>
        </div>

        <div class="ticket-body-item">
          <h2 class="text-body-1 color--text-secondary mb-2">{{ $t("Description") }}</h2>
          <p class="text-subtitle-1">
            {{ ticket?.details }}
          </p>
        </div>

        <!-- TODO: Add solution functionality -->
        <!-- <div class="ticket-body-item">
          <h2 class="text-body-1 color--text-secondary mb-2">{{ $t("Solution") }}</h2>
          <p class="text-subtitle-1">
            {{ ticket.details }}
          </p>
        </div> -->
      </div>

      <TicketDetailsControl
        class="ticket-control"
        :ticket="ticket"
        :users="users"
        :refetch="refetch"
      />

      <!-- TODO: Add History then reenable tabs -->
      <TicketDetailsActivity
        class="ticket-comments"
        :filters="filters"
        :new-comment-params="commentParams"
        :tabs="[
          // {
          //   label: $t('All')
          // },
          // {
          //   label: $t('Comments')
          // },
          // { label: $t('History') }
        ]"
      />

      <TicketDetailsSolutionDialog
        :open="showSolutionDialog"
        :ticket="ticket"
        @close="showSolutionDialog = false"
      />
    </div>
  </div>
</template>

<script setup>
import { computed, ref } from "vue";
import { useRoute } from "vue-router/composables";

import Btn from "@/ui/Btn";
import TicketDetailsActivity from "./components/TicketDetailsActivity.vue";
import TicketDetailsSolutionDialog from "./components/TicketDetailsSolutionDialog.vue";
import TicketDetailsControl from "./components/TicketDetailsControl.vue";
import { useTicketDetails } from "./useTicketDetails";

const $route = useRoute();

const variables = computed(() => ({
  id: $route.params.ticket_id
}));
const { ticket, users, refetch } = useTicketDetails(variables);

const categoryIssue = computed(() => ticket.value?.issues?.[0] || null);
const qualityIssues = computed(() => ticket.value?.issues?.slice(1) || []);

const backToTickets = () => {
  if (window.history.length > 1) {
    window.history.back();
  } else {
    window.close();
  }
};

const filters = computed(() => ({
  ticketId: { value: $route.params.ticket_id }
}));

const commentParams = computed(() => ({
  ticketId: $route.params.ticket_id
}));

const showSolutionDialog = ref(false);

defineExpose({
  categoryIssue,
  qualityIssues,
  commentParams,
  filters,
  ticket,
  users,
  showSolutionDialog,
  backToTickets,
  refetch
});
</script>

<style lang="scss" scoped>
@import "~@/scss/variables";
@import "~@/scss/mq";

.type {
  display: grid;
  gap: 6px 60px;
  grid-template-columns: auto 1fr;
}

.composition {
  display: grid;
  grid-template-areas:
    "header control"
    "body control"
    "comments control";
  column-gap: 32px;
  grid-template-columns: auto 460px;
  grid-template-rows: auto auto 1fr;

  @include media_below(1367px) {
    grid-template-columns: auto 360px;
  }

  @include media_below(1025px) {
    @media screen and (orientation: portrait) {
      grid-template-areas:
        "header"
        "body"
        "control"
        "comments";
      column-gap: 32px;
      grid-template-columns: 1fr;
      grid-template-rows: auto;
    }
  }
}

.ticket-issue {
  margin: 0 5px;
}

.ticket-header {
  grid-area: header;
  min-height: 44px;
  display: flex;
  align-items: center;
}

.ticket-body {
  grid-area: body;

  @media (max-width: 950px) and (orientation: landscape) {
    margin-bottom: 24px !important;
  }

  @media (max-width: 1100px) and (orientation: portrait) {
    margin-bottom: 24px !important;
  }
}

.ticket-body-item {
  & ~ .ticket-body-item {
    margin-top: 16px;
  }
}

.ticket-control {
  grid-area: control;
}

.ticket-comments {
  grid-area: comments;
}
</style>
